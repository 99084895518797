import React from "react";
import {
    Form,
    Button,
    Row,
    Col,
    ButtonGroup,
    Card,
    InputGroup,
} from "react-bootstrap";

export default function Question({
    sum,
    onSelectAnswer,
    questionNo,
    selectedOption,
    handleNextQuestion,
}) {
    const handleOptionSelect = (sum_id, option_id) => {
        onSelectAnswer(sum_id, option_id);
        setTimeout(() => handleNextQuestion(), 300);
    };

    return (
        <>
            <Card.Title className="mb-3">Question {questionNo + 1}</Card.Title>
            <br />
            <div className="mb-4">
                <h5>{sum.question}</h5>
            </div>
            <br />
            <Row>
                <Col md="12">
                    <h5>Answers:</h5>
                </Col>
                <br />
                <br />
            </Row>
            <Row>
                {sum.sumOptions.map((option, index) => (
                    <Col md="3" key={index}>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                className="option-block"
                                type="checkbox"
                                id={`option-${index}`}
                                checked={selectedOption === option.id}
                                onChange={() =>
                                    handleOptionSelect(sum.id, option.id)
                                }
                            />
                            <InputGroup.Text>
                                {option.optionText}
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                ))}
            </Row>
        </>
    );
}
