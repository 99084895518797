import { SchoolTypes as ActionConstant } from "../../Actions/Types/Schools";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    schoolId: 0,
    schools: [],
    school: null,
};

const SchoolsReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.CREATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.UPDATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.UPDATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.DELETE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET:
            state = {
                ...state,
                school: null,
                PROCESSING: true,
            };
            break;

        case ActionConstant.UPDATE_SCHOOL_PROFILE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;

        case ActionConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                school: action.payload.object,
            };
            break;

        case ActionConstant.UPDATE_SCHOOL_PROFILE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                school: action.payload.object,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET_ALL:
            state = {
                ...state,
                PROCESSING: true,
                schools: [],
            };
            break;
        case ActionConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                schools: action.payload.objects,
            };
            break;

        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default SchoolsReducer;
