import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import * as Utils from "../Util/util";
import DataTable from "../Components/DataTable";
import NewClassDialog from "./NewClass";
import RequestStatus from "../Components/RequestStatus";
import { getAll } from "../Store/Actions/Admin/Classes";

const ListClasses = (props) => {
    const classState = useSelector((state) => state.classes);
    const classes = classState.classes;
    const columns = [
        {
            dataField: "id",
            text: "ID",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "name",
            text: "Class Name",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
    ];

    return (
        <div>
            <br />
            {classes ? (
                <DataTable
                    keyField="id"
                    columns={columns}
                    data={classes}
                    search={true}
                    pagination={true}
                    button={false}
                    dialogButton={true}
                    dialogAction={props.setDialog}
                    buttonLabel="Add New Class"
                    className="transaction-table"
                />
            ) : (
                ""
            )}
        </div>
    );
};

const Classes = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [showDialog, setDialog] = useState(false);
    const [state, setState] = useState({});

    const stateObject = useSelector((state) => state.classes);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const pageNavItems = [
        {
            title: "Fixed Deposits",
            active: true,
            to: "/fixed-deposits",
        },
    ];

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={12}>
                        <NewClassDialog
                            setAlertStatus={setAlertStatus}
                            showDialog={showDialog}
                            setDialog={setDialog}
                        />
                        <ListClasses setDialog={setDialog} />
                    </Col>
                </Row>
                <div className="text-center mt-5">
                    <RequestStatus
                        stateObj={stateObject}
                        alertStatus={alertStatus}
                        setAlertStatus={setAlertStatus}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

export default Classes;
