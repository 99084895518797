import moment from "moment";
import jwt_decode from "jwt-decode";
import { Badge } from "react-bootstrap";

export const getSession = () => {
    try {
        const access_token = localStorage.getItem("access_token");
        const decoded_info = jwt_decode(access_token);
        return { ...decoded_info.user };
    } catch (error) {
        console.log(error);
    }
};
export const formatDateInTable = (cell, row) => {
    return moment(cell.toString()).format("YYYY-MM-DD h:mm:ss");
};

export const now = () => {
    return moment().format("YYYY-MM-DD");
};

export const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
};

export const getNextDate = (date, timeVal, indentifier) => {
    const formatedDate = moment(date).format("YYYY-MM-DD");

    return new Date(
        moment(formatedDate).add(timeVal, indentifier).format("YYYY-MM-DD")
    );
};

export const getOptions = (dataObj, label, value) => {
    let options = [];
    dataObj.map((item, index) => (options[item[value]] = item[label]));
    return options;
};

export const getSearchableOptions = (dataObj, label, value) => {
    let options = [];
    options[0] = { label: "Select", value: "" };
    dataObj.map(
        (item, index) =>
            (options[index + 1] = {
                label: item[label],
                value: item[value],
            })
    );
    return options;
};

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const currencyFormat = (cell, row = null) => {
    return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
    }).format(cell);
};

export const Search = (arr, searchKey) => {
    return arr.find((item) => {
        if (item.id === searchKey) {
            return item;
        }
    });
};

export const getYearOptions = () => {
    return { 1: "1 Year", 2: "2 Year", 3: "3 Year", 4: "4 Year", 5: "5 Year" };
};

export const dateDiff = (fromDateStr, toDateStr) => {
    const fromDate = moment(fromDateStr);
    const toDate = moment(toDateStr);
    const days = Math.abs(toDate.diff(fromDate, "days"));
    return days;
};

export const getNoOfDays = (fromDate, installments) => {
    let noOfDays = 0;
    let date = fromDate;
    for (let i = 1; i <= installments; i++) {
        date = moment(date).add(1, "month").calendar();
        date = moment(date).format("YYYY-MM-DD");
        noOfDays += moment(date, "YYYY-MM").daysInMonth();
    }
    return noOfDays - 1;
};

export const showBadge = (cell, row) => {
    if (row.status === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Active
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                Deleted
            </Badge>
        );
    }
};

export const showPublishedBadge = (cell, row) => {
    if (row.isPublished === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Published
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                Un-Published
            </Badge>
        );
    }
};

export const showPremiumBadge = (cell, row) => {
    if (row.isPremium === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Yes
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                No
            </Badge>
        );
    }
};
