import { AuthTypes } from "./Types/Auth";

export const login = (data) => {
    return {
        type: AuthTypes.LOGIN_REQUEST,
        payload: data,
    };
};

export const loginSuccess = (user) => {
    return {
        type: AuthTypes.LOGIN_SUCCESS,
        payload: user,
    };
};

export const loginError = (error) => {
    return {
        type: AuthTypes.LOGIN_ERROR,
        payload: error,
    };
};

export const logout = () => {
    return {
        type: AuthTypes.LOGOUT_REQUEST,
        payload: null,
    };
};
export const logoutSuccess = (data) => {
    return {
        type: AuthTypes.LOGOUT_SUCCESS,
        payload: data,
    };
};

export const refreshTokenRequest = (data) => {
    return {
        type: AuthTypes.TOKEN_REFRESH_REQUEST,
        payload: data,
    };
};

export const refreshTokenSuccess = (data) => {
    return {
        type: AuthTypes.TOKEN_REFRESH_SUCCESS,
        payload: data,
    };
};
export const isAuthenticated = () => {
    return {
        type: AuthTypes.IS_AUTHENTICATED,
        payload: null,
    };
};

export const getAuthenticatedUser = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
};
