import getActions from "../Base";
import { SchoolTypes } from "../Types/Schools";

const baseActions = getActions(SchoolTypes);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const updateProfile = (data) => {
    return {
        type: SchoolTypes["UPDATE_SCHOOL_PROFILE"],
        payload: data,
        success: SchoolTypes["UPDATE_SCHOOL_SUCCESS"],
    };
};

export { create, update, remove, getAll, getOne, failed, updateProfile };
