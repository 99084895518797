import { DashboardTypes } from "./Types/Dashboard";

const getSchollDashboard = (data = null) => {
    return {
        type: DashboardTypes.GET_SCHOOL_DASHBOARD,
        payload: data,
        success: DashboardTypes.GET_SCHOOL_DASHBOARD_SUCCESS,
    };
};

const getStudentDashboard = (data = null) => {
    return {
        type: DashboardTypes.GET_STUDENT_DASHBOARD,
        payload: data,
        success: DashboardTypes.GET_STUDENT_DASHBOARD_SUCCESS,
    };
};

const failed = (data) => {
    return {
        type: DashboardTypes["ERROR"],
        payload: data,
    };
};

export { getSchollDashboard, getStudentDashboard, failed };
