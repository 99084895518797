import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Button, Offcanvas, Form, InputGroup } from "react-bootstrap";
import { TextArea } from "../../Components/Input";
import { updateSum } from "../../Store/Actions/Admin/Exams";

const EditSum = (props) => {
    const [updatedAt, setUpdatedDate] = useState(new Date());
    const [question, setQuestion] = useState("");

    const [inputFields, setInputFields] = useState([
        { text: "", isChecked: false },
        { text: "", isChecked: false },
        { text: "", isChecked: false },
        { text: "", isChecked: false },
    ]);

    const handleAddField = () => {
        setInputFields([...inputFields, { text: "", isChecked: false }]);
    };

    const handleInputChange = (index, value) => {
        const newInputFields = [...inputFields];
        newInputFields[index].text = value;
        setInputFields(newInputFields);
    };

    const handleRadioChange = (index) => {
        const newInputFields = inputFields.map((field, i) => {
            if (i === index) {
                return { ...field, isChecked: !field.isChecked };
            } else {
                return { ...field, isChecked: false };
            }
        });

        setInputFields(newInputFields);
    };

    const stateObject = useSelector((state) => state.exams);
    const sum = stateObject.sum;

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const setInitialState = () => {
        setQuestion(sum.question);
        if (sum.sumOptions) {
            const options = sum.sumOptions.map((item) => ({
                text: item.optionText,
                id: item.id,
                isChecked: item.isRightOption,
            }));
            setInputFields(options);
        }
    };

    useEffect(() => {
        if (sum) {
            setInitialState();
        }
    }, [sum]);

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const options = inputFields.map((input) => ({
            text: input.text,
            isCorrectAnswer: input.isChecked ? 1 : 0,
        }));
        const data = {
            examId: props.examId,
            question,
            options,
            updatedAt,
            id: sum.id,
        };

        dispatch(updateSum(data));
    };

    const resetForm = (e) => {
        e.preventDefault();
        setQuestion("");
    };

    const handleRemoveField = (index) => {
        const newInputFields = [...inputFields];
        newInputFields.splice(index, 1);
        setInputFields(newInputFields);
    };

    const onChange = (value) => {
        setQuestion(value);
        if (props.onChange) {
            props.onChange(value.toString("html"));
        }
    };
    return (
        <Offcanvas
            placement="end"
            backdrop="true"
            show={props.showDialog}
            onHide={props.setDialog}
            {...props}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Sum</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={12}>
                            <TextArea
                                type="text"
                                name="question"
                                label="Question"
                                placeholder="Question"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                rows={6}
                            />
                        </Col>
                    </Row>
                    <br />
                    {inputFields.map((input, index) => (
                        <div key={index}>
                            <Row>
                                <Col md={12}>
                                    Option {index + 1}
                                    <InputGroup>
                                        <InputGroup.Radio
                                            aria-label="Is Correct Answer"
                                            checked={input.isChecked}
                                            onChange={() =>
                                                handleRadioChange(index)
                                            }
                                        />
                                        <Form.Control
                                            aria-label="Option 1"
                                            value={input.text}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                        />

                                        <Button
                                            variant="danger"
                                            onClick={() =>
                                                handleRemoveField(index)
                                            }
                                        >
                                            <i className="fa fa-trash" />
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <br />
                        </div>
                    ))}
                    <Row>
                        <Col md={12} className="text-right">
                            <Button
                                variant="outline-primary"
                                onClick={handleAddField}
                            >
                                Add Options
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="mt-2 text-middle">
                            <Button variant="primary" type="Submit">
                                Update
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant="secondary"
                                onClick={resetForm}
                                type="Button"
                            >
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default EditSum;
