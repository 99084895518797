import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDashboard,
    faUser,
    faLayerGroup,
    faCube,
    faSchoolFlag,
    faTags,
} from "@fortawesome/free-solid-svg-icons";

import { NavLink } from "react-router-dom";
import { getAuthenticatedUser } from "../Store/Actions/Auth";

const AdminSideBar = ({ open }) => {
    return (
        <div className={`sidebar ${open ? "" : "closed"}`}>
            <div className="sidebar-content">
                <NavLink to="/" exact="true">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faDashboard} />
                    </span>
                    {open ? "Dashboard" : ""}
                </NavLink>

                <NavLink to="/category">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </span>
                    {open ? "Category" : ""}
                </NavLink>

                <NavLink to="/exams">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </span>
                    {open ? "Exams" : ""}
                </NavLink>

                <NavLink to="/packages">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faCube} />
                    </span>
                    {open ? "Packages" : ""}
                </NavLink>
                <NavLink to="/schools">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faSchoolFlag} />
                    </span>
                    {open ? "Schools" : ""}
                </NavLink>

                <NavLink to="/assign-packages">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faTags} />
                    </span>
                    {open ? "Assign Packages" : ""}
                </NavLink>

                <NavLink to="/assign-exams">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faTags} />
                    </span>
                    {open ? "Assign Exams" : ""}
                </NavLink>
            </div>
        </div>
    );
};

const SchoolSideBar = ({ open }) => {
    return (
        <div className={`sidebar ${open ? "" : "closed"}`}>
            <div className="sidebar-content">
                <NavLink to="/" exact="true">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faDashboard} />
                    </span>
                    {open ? "Dashboard" : ""}
                </NavLink>

                <NavLink to="/students">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                    {open ? "Students" : ""}
                </NavLink>

                {/*  <NavLink to="/purchase-exams">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </span>
                    {open ? "Purchase Exams" : ""}
                </NavLink>

                <NavLink to="/purchase-packages">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faCube} />
                    </span>
                    {open ? "Purchase Packages" : ""}
                </NavLink>
 */}
                <NavLink to="/purchased-exams">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </span>
                    {open ? "My Exams" : ""}
                </NavLink>

                <NavLink to="/purchased-packages">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faCube} />
                    </span>
                    {open ? "My Packages" : ""}
                </NavLink>

                <NavLink to="/profile">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faSchoolFlag} />
                    </span>
                    {open ? "Profile" : ""}
                </NavLink>
            </div>
        </div>
    );
};

const StudentSideBar = ({ open }) => {
    return (
        <div className={`sidebar ${open ? "" : "closed"}`}>
            <div className="sidebar-content">
                <NavLink to="/" exact="true">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faDashboard} />
                    </span>
                    {open ? "Dashboard" : ""}
                </NavLink>
                {/* 
                <NavLink to="/purchase-exams">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </span>
                    {open ? "Purchase Exams" : ""}
                </NavLink>

                <NavLink to="/purchase-packages">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faCube} />
                    </span>
                    {open ? "Purchase Packages" : ""}
                </NavLink> */}

                <NavLink to="/purchased-exams">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </span>
                    {open ? "My Exams" : ""}
                </NavLink>

                <NavLink to="/purchased-packages">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faCube} />
                    </span>
                    {open ? "My Packages" : ""}
                </NavLink>

                <NavLink to="/attempted-exams">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </span>
                    {open ? " Exams Attempted" : ""}
                </NavLink>

                <NavLink to="/profile">
                    <span className="fa-icon">
                        <FontAwesomeIcon icon={faSchoolFlag} />
                    </span>
                    {open ? "Profile" : ""}
                </NavLink>
            </div>
        </div>
    );
};

const Sidebar = ({ open }) => {
    const user = getAuthenticatedUser();
    if (!user) {
        return null;
    }
    if (user.role === "admin") {
        return <AdminSideBar open={open} />;
    } else if (user.role === "school-admin") {
        return <SchoolSideBar open={open} />;
    } else if (user.role == "student") {
        return <StudentSideBar open={open} />;
    }
};

export default Sidebar;
