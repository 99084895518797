import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Col,
    Button,
    Form,
    Card,
    Breadcrumb,
    ButtonToolbar,
    ButtonGroup,
    Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input, SelectInput } from "../Components/Input";
import { getOptions } from "../Util/util";

import RequestStatus from "../Components/RequestStatus";
import { getAll as getAllCategories } from "../Store/Actions/Admin/Category";
import { getUserPackages } from "../Store/Actions/Admin/Packages";
import ExamCard from "../Components/ExamsCard";

const ListPurchasedPackages = ({ packages }) => {
    return (
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
            {packages.map((item, idx) => (
                <Accordion.Item eventKey={idx} className="mt-4" key={idx}>
                    <Accordion.Header>
                        {item.name} - {item.description}
                    </Accordion.Header>
                    <Accordion.Body>
                        <ExamCard exams={item.exams} purchased={true} />
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

const PurchasedPackages = (props) => {
    const [state, setState] = useState({
        name: "",
        categoryId: "",
        currentPage: 1,
        pagination: true,
    });
    const [alertStatus, setAlertStatus] = useState(true);

    const packageState = useSelector((state) => state.packages);
    const packages = packageState.userPackages;
    const totalPages = packageState.userTotalPages;
    const categoryState = useSelector((state) => state.categories);

    let errors = [];
    if (typeof packageState.errors !== "undefined") {
        packageState.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    const getPackages = () => {
        dispatch(getUserPackages(state));
    };

    useEffect(() => {
        dispatch(getAllCategories());
        getPackages();
    }, [dispatch]);

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const resetForm = (e) => {
        e.preventDefault();
        setState({
            name: "",
            categoryId: "",
            currentPage: 1,
        });
    };

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        dispatch(getUserPackages({ ...state }));
    };

    const getPaginationButtons = () => {
        if (totalPages === 1) {
            return <></>;
        }
        const buttons = [];
        for (let i = 1; i <= totalPages; i++) {
            buttons.push(<Button key={i}>{i}</Button>);
        }
        return buttons;
    };

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <Breadcrumb.Item active>Purchase Packages</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={4}>
                                <Input
                                    type="text"
                                    name="name"
                                    label="Package Name"
                                    placeholder="Search Package By Name"
                                    value={state.name}
                                    onChange={(e) =>
                                        onChangeHandler("name", e.target.value)
                                    }
                                />
                            </Col>
                            <Col md={4}>
                                <SelectInput
                                    errMessage={errors["categoryId"]}
                                    name="categoryId"
                                    placeholder="Category"
                                    label="Category"
                                    type="text"
                                    defaulttext="All"
                                    Options={getOptions(
                                        categoryState.categories,
                                        "name",
                                        "id"
                                    )}
                                    value={state.categoryId}
                                    handleChange={(e) =>
                                        onChangeHandler(
                                            "categoryId",
                                            e.target.value
                                        )
                                    }
                                    controlId="Category"
                                />
                            </Col>
                            <Col md="4" className="mt-2 text-middle">
                                <br />
                                <Button variant="primary" type="Submit">
                                    Search
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    variant="secondary"
                                    onClick={resetForm}
                                    type="Button"
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <br />
            <ListPurchasedPackages packages={packages} />
            <div fixed="bottom" className="mt-4 text-right">
                <ButtonToolbar
                    align="end"
                    aria-label="Pages"
                    className="justify-content-end"
                >
                    <ButtonGroup className="me-2" aria-label="First group">
                        {getPaginationButtons()}
                    </ButtonGroup>
                </ButtonToolbar>
            </div>

            <div className="text-center mt-6">
                <RequestStatus
                    stateObj={packageState}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
};

export default PurchasedPackages;
