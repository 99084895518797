import React, { useState } from "react";
import "../../home.css";
import logoImg from "../../assets/logo-bg.jpg";
import locationImg from "../../assets/location.png";
import bannerVectorImg from "../../assets/banner-vector.png";
import { useNavigate } from "react-router-dom";
function Home() {
    const navigate = useNavigate();
    const [selectedSection, setSelectedSection] = useState("banner");
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            setSelectedSection(id);
        }
    };

    const SignIn = () => {
        navigate(`/signin`);
    };

    return (
        <div className="home-container">
            <header>
                <div className="logo">
                    <img src={logoImg} alt="Elitemindmap" />
                </div>
                <nav>
                    <button
                        className={
                            selectedSection === "banner" ? "selected" : ""
                        }
                        onClick={() => scrollToSection("banner")}
                    >
                        Home
                    </button>
                    <button
                        className={
                            selectedSection === "about" ? "selected" : ""
                        }
                        onClick={() => scrollToSection("about")}
                    >
                        About Us
                    </button>
                    <button
                        className={selectedSection === "why" ? "selected" : ""}
                        onClick={() => scrollToSection("why")}
                    >
                        Why Us
                    </button>
                    <button
                        className={
                            selectedSection === "contact" ? "selected" : ""
                        }
                        onClick={() => scrollToSection("contact")}
                    >
                        Contact Us
                    </button>

                    <button onClick={SignIn}>Sign-In</button>
                </nav>
            </header>

            <section id="banner" class="banner">
                <div class="vector-img">
                    <img src={bannerVectorImg} alt="" />
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-7">
                        <div class="banner-content">
                            <h1>
                                Prepare Your Child for Success with Fun and
                                Effective Learning Techniques
                            </h1>
                            <p>
                                Enriching and Interactive Educational
                                Experiences for Kids
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <main>
                <section id="about">
                    <h2>About Us</h2>
                    <p>
                        Elite Mindmap is an academy for children's brain
                        development with higher cognitive skills. It was started
                        by Shruti.JM in 2022 with the intention of providing
                        learning platforms to children who wish to equip
                        themselves with creative, logical and analytical skills
                        through our brain-teasing activities and courses. These
                        time-tested proven activities have empowered many
                        personalities in past and are heling many In recent
                        times.
                        <br />
                        Out Visiion is to empower young minds with competitive
                        Intelligence and expressive temperament by sharpening
                        cognitive and soft-skill talents.
                    </p>
                </section>
                <section id="why">
                    <h2>Why Us</h2>
                    <ul>
                        <li>
                            Mentors & Trainers have more than 5-6 years of
                            experience in training students in related courses
                            and brain development activities.
                        </li>
                        <li>Personalized approach toward every student.</li>
                        <li>
                            Assessment to gauge the development of students.
                        </li>
                        <li>
                            Interactive and engaging sessions
                            <br /> Small batches of students for addressing
                            individual concerns and attention.
                        </li>
                        <li>
                            Regular interaction with parents on student's
                            development status.
                        </li>
                        <li>
                            Support for student's participation in any
                            regional/internation level competition.
                        </li>
                    </ul>
                </section>
                <section id="contact" className="contact-section">
                    <div className="contact-header">
                        <h2>Contact Us</h2>
                    </div>
                    <div className="contact-content">
                        <div className="contact-details">
                            <p>
                                <h5>Elite Mindmap</h5>
                                #5/41 Sameera Krupa, 2nd Floor, 1st Main, 1st
                                Stage, KHB Colony, Basaveshwar Nagar,
                                Bengaluru,79
                                <br />
                                <br />
                                <h5>Phone Number</h5>
                                9108445621 <br /> 7349163186
                                <br />
                                <br />
                                <h5>Email ID</h5> elitemindmap@gmail.com
                            </p>
                        </div>
                        <div className="image-section">
                            <a
                                target="_blank"
                                href="https://www.google.com/maps/search/%235%2F41+Sameera+Krupa,+2nd+Floor,+1st+Main,+1st+Stage,+KHB+Colony,+Basaveshwar+Nagar,+Bengaluru,79/@12.9851221,77.5349741,19z/data=!3m1!4b1?entry=ttu"
                            >
                                <img src={locationImg} alt="Contact US Here" />
                            </a>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <p>&copy; 2022 Elitemindmap. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default Home;
