import getActions from "../Base";
import { ClassTypes } from "../Types/Classes";

const baseActions = getActions(ClassTypes);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

export { create, update, remove, getAll, getOne, failed };
