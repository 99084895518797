import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getAuthenticatedUser } from "../Store/Actions/Auth";

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
    role: state.auth.user.role,
});

const AdminRoutes = ({ authenticated, role, element }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = getAuthenticatedUser();
        if (!user) {
            navigate("/home");
        } else if (user.role !== "admin") {
            navigate("/forbidden");
        }
    }, [navigate]);

    if (!authenticated) {
        // Handle the case where authentication status is not available yet.
        return null;
    }

    return authenticated && role === "admin" ? element : null;
};

export default connect(mapStateToProps)(AdminRoutes);
