import { all } from "redux-saga/effects";
import { watchAuthRequests } from "./Auth";
import { watchCategoryRequests } from "./Admin/Category";
import { watchClassRequests } from "./Admin/Classes";
import { watchExamRequests } from "./Admin/Exams";
import { watchSchoolRequests } from "./Admin/Schools";
import { watchPackageRequests } from "./Admin/Packages";
import { watchStudentRequests } from "./Student";
import { watchDashboadRequests } from "./Dashboard";

export default function* RootSaga() {
    yield all([
        //Auth
        watchAuthRequests(),

        //Classes
        watchClassRequests(),

        //Exams
        watchExamRequests(),

        //Schools
        watchSchoolRequests(),

        //Packages
        watchPackageRequests(),

        //Category
        watchCategoryRequests(),

        //Student
        watchStudentRequests(),

        //Dashboards
        watchDashboadRequests(),
    ]);
}
