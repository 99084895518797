import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Badge, Breadcrumb, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    getAllExamsAttempted,
    getExamAttempt,
} from "../Store/Actions/Admin/Exams";
import RequestStatus from "../Components/RequestStatus";
import { useNavigate } from "react-router-dom";

const ListExamsAttempted = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state = useSelector((state) => state.exams);
    const examAttempts = state.examAttempts;

    const getDuration = (startTime, endTime) => {
        const startDT = new Date(startTime);
        const endDT = new Date(endTime);
        return Math.ceil((endDT - startDT) / 1000 / 60);
    };

    const completeExamAttempt = (examId, examAttemptId) => {
        dispatch(getExamAttempt({ examAttemptId }));
        navigate(`/attempt-exam/${examId}/${examAttemptId}`);
    };

    useEffect(() => {
        dispatch(getAllExamsAttempted());
    }, []);
    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/attempted-exams">Exams</Link>
                </li>
            </Breadcrumb>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className="text-center">Sl.No</th>
                        <th className="text-center">Exam</th>
                        <th className="text-center">Start Time</th>
                        <th className="text-center">End Time</th>
                        <th className="text-center">Duration</th>
                        <th className="text-center">Score</th>
                        <th className="text-center">...</th>
                    </tr>
                </thead>
                <tbody>
                    {examAttempts.map((item, idx) => (
                        <tr key={idx}>
                            <td className="text-center">{idx + 1}</td>
                            <td className="text-center">{item.exam.name}</td>
                            <td className="text-center">
                                {moment(item.startTime).format(
                                    "YYYY-MM-DD h:mm:ss "
                                )}
                            </td>
                            {item.isCompleted === 1 ? (
                                <>
                                    <td className="text-center">
                                        {moment(item.endTime).format(
                                            "YYYY-MM-DD h:mm:ss "
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {getDuration(
                                            item.startTime,
                                            item.endTime
                                        )}
                                        &nbsp;Min
                                    </td>
                                    <td className="text-center">
                                        {item.totalScore} %
                                    </td>
                                    <td className="text-center">
                                        <Button
                                            size="sm"
                                            type="button"
                                            variant="success"
                                            as={Link}
                                            to={`/exam-attempt-details/${item.examId}/${item.id}`}
                                        >
                                            Details
                                        </Button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td colSpan={3} className="text-center">
                                        <Badge
                                            bg="primary"
                                            className="text-white"
                                        >
                                            In-Progress
                                        </Badge>
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            type="button"
                                            variant="primary"
                                            type="button"
                                            onClick={() =>
                                                completeExamAttempt(
                                                    item.examId,
                                                    item.id
                                                )
                                            }
                                        >
                                            Complete Exam
                                        </Button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className="text-center">
                <RequestStatus
                    stateObj={state}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
};

export default ListExamsAttempted;
