import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import RequestStatus from "../../Components/RequestStatus";
import { getStudentDashboard } from "../../Store/Actions/Dashboard";

export default function Dashboard() {
    const [state, setState] = useState({});
    const [alertStatus, setAlertStatus] = useState(true);
    const stateObject = useSelector((state) => state.dashboards);
    const data = stateObject.data;

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStudentDashboard());
    }, [dispatch]);

    useEffect(() => {
        setInitState();
    }, [data]);

    const setInitState = () => {
        if (data) {
            setState({
                ...data,
            });
        }
    };

    return (
        <>
            <Row>
                <Col md={4}>
                    <Card bg="light" key={"exams_count"}>
                        <Card.Body>
                            <Card.Title>Purchased Exams</Card.Title>
                            <Card.Text>
                                {data && data.examsCount ? data.examsCount : 0}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card bg="light" key={"package_count"}>
                        <Card.Body>
                            <Card.Title>Purchased Packages</Card.Title>
                            <Card.Text>
                                {data && data.packageCount
                                    ? data.packageCount
                                    : 0}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div className="text-center mt-5">
                <RequestStatus
                    stateObj={stateObject}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
}
