import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";

import { Input } from "../Components/Input";
import { create } from "../Store/Actions/Admin/Classes";

const NewClassDialog = (props) => {
    const [state, setState] = useState({
        className: "",
        createdDate: new Date(),
    });

    const stateObject = useSelector((state) => state.classes);

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const data = { ...state };
        dispatch(create(data));
    };

    const resetForm = (e) => {};

    return (
        <Modal show={props.showDialog} onHide={props.setDialog} centered="true" size="md">
            <Modal.Header closeButton>
                <Modal.Title>Add Class</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={6}>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                label="Class Name"
                                placeholder="Class Name"
                                value={state.name}
                                onChange={(e) => onChangeHandler("name", e.target.value)}
                            />
                        </Col>

                        <Col md="6" className="mt-2">
                            <br />
                            <Button variant="primary" type="Submit">
                                Create
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={resetForm} type="Button">
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default NewClassDialog;
