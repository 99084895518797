import { getTypes } from "./TypeGenerator";

export const ExamTypes = {
    ...getTypes("EXAMS"),

    RESET_EXAM_ATTEMPT_STATE: "RESET_EXAM_ATTEMPT_STATE",
    RESET_EXAM_ATTEMPT_STATE_SUCCESS: "RESET_EXAM_ATTEMPT_STATE_SUCCESS",

    GET_SUMS: "GET_SUMS",
    GET_SUMS_SUCCESS: `GET_SUMS_SUCCESS`,

    GET_SUM: "GET_SUM",
    GET_SUM_SUCCESS: `GET_SUM_SUCCESS`,

    CREATE_SUM: "CREATE_SUM",
    CREATE_SUM_SUCCESS: `CREATE_SUM_SUCCESS`,

    UPDATE_SUM: "UPDATE_SUM",
    UPDATE_SUM_SUCCESS: `UPDATE_SUM_SUCCESS`,

    UPLOAD_SUMS: "UPLOAD_SUMS",
    UPLOAD_SUMS_SUCCESS: "UPLOAD_SUMS_SUCCESS",

    GET_PUBLISHED_EXAMS: "GET_PUBLISHED_EXAMS",
    GET_PUBLISHED_EXAMS_SUCCESS: "GET_PUBLISHED_EXAMS_SUCCESS",

    GET_UNPUBLISHED_EXAMS: "GET_UNPUBLISHED_EXAMS",
    GET_UNPUBLISHED_EXAMS_SUCCESS: "GET_UNPUBLISHED_EXAMS_SUCCESS",

    GET_EXAMS_BY_QUERY_STRING: "GET_EXAMS_BY_QUERY_STRING",
    GET_EXAMS_BY_QUERY_STRING_SUCCESS: "GET_EXAMS_BY_QUERY_STRING_SUCCESS",

    GET_EXAMS_FOR_SCHOOL: "GET_EXAMS_FOR_SCHOOL",
    GET_EXAMS_FOR_SCHOOL_SUCCESS: "GET_EXAMS_FOR_SCHOOL_SUCCESS",

    SEARCH_EXAMS: "SEARCH_EXAMS",
    SEARCH_EXAMS_SUCCESS: "SEARCH_EXAMS_SUCCESS",

    PURCHASE_EXAMS: "PURCHASE_EXAMS",
    PURCHASE_EXAMS_SUCCESS: "PURCHASE_EXAMS_SUCCESS",

    GET_USER_EXAMS: "GET_USER_EXAMS",
    GET_USER_EXAMS_SUCCESS: "GET_USER_EXAMS_SUCCESS",

    EXAM_ATTEMPT: "EXAM_ATTEMPT",
    EXAM_ATTEMPT_SUCCESS: "EXAM_ATTEMPT_SUCCESS",

    UPDATE_QUESTION_ANSWER: "UPDATE_QUESTION_ANSWER",
    UPDATE_QUESTION_ANSWER_SUCCESS: "UPDATE_QUESTION_ANSWER_SUCCESS",

    COMPLETE_EXAM_ATTEMPT: "COMPLETE_EXAM_ATTEMPT",
    COMPLETE_EXAM_ATTEMPT_SUCCESS: "COMPLETE_EXAM_ATTEMPT_SUCCESS",

    GET_EXAM_ATTEMPT: "GET_EXAM_ATTEMPT",
    GET_EXAM_ATTEMPT_SUCCESS: "GET_EXAM_ATTEMPT_SUCCESS",

    GET_EXAM_ATTEMPT_RESULT: "GET_EXAM_ATTEMPT_RESULT",
    GET_EXAM_ATTEMPT_RESULT_SUCCESS: "GET_EXAM_ATTEMPT_RESULT_SUCCESS",

    GET_ALL_EXAMS_ATTEMPTED: "GET_ALL_EXAMS_ATTEMPTED",
    GET_ALL_EXAMS_ATTEMPTED_SUCCESS: "GET_ALL_EXAMS_ATTEMPTED_SUCCESS",

    GET_EXAMS_ASSIGNGED_TO_SCHOOL: "GET_EXAMS_ASSIGNGED_TO_SCHOOL",
    GET_EXAMS_ASSIGNGED_TO_SCHOOL_SUCCESS:
        "GET_EXAMS_ASSIGNGED_TO_SCHOOL_SUCCESS",

    ASSIGN_EXAMS_TO_SCHOOL: "ASSIGN_EXAMS_TO_SCHOOL",
    ASSIGN_EXAMS_TO_SCHOOL_SUCCESS: "ASSIGN_EXAMS_TO_SCHOOL_SUCCESS",

    DELETE_SUM: "DELETE_SUM",
    DELETE_SUM_SUCCESS: `DELETE_SUM_SUCCESS`,

    DOWNLOAD_CSV: "DOWNLOAD_CSV",
    DOWNLOAD_CSV_SUCCESS: "DOWNLOAD_CSV_SUCCESS",
};
