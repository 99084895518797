import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "react-bootstrap";
import * as Utils from "../../../Util/util";
import DataTable from "../../../Components/DataTable";
import { getAll as getAllCategories } from "../../../Store/Actions/Admin/Category";
import { getAll, getOne } from "../../../Store/Actions/Admin/Packages";

const ListPackages = (props) => {
    const packageState = useSelector((state) => state.packages);
    const dispatch = useDispatch();

    const getPackage = (id) => {
        dispatch(getOne({ id }));
    };

    useEffect(() => {
        dispatch(getAll());
        dispatch(getAllCategories());
    }, [dispatch]);

    const actionButtons = (cell, row) => {
        return (
            <>
                <ButtonGroup aria-label="Actions" size="sm" vertical>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                            getPackage(row.id);
                            props.setEditDialog(true);
                        }}
                    >
                        <i className="fa fa-edit" aria-hidden="true" /> Edit
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    const columns = [
        {
            dataField: "name",
            text: "Name",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "description",
            text: "Description",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "subscriptionCharges",
            text: "Charges",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "isPublished",
            text: "Published",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showPublishedBadge,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
        {
            dataField: "#",
            text: "...",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    return (
        <div>
            <DataTable
                keyField="id"
                columns={columns}
                data={packageState.packages ?? []}
                search={true}
                pagination={true}
                button={false}
                dialogButton={true}
                dialogAction={props.setDialog}
                buttonLabel="Add New"
                className="transaction-table"
            />
        </div>
    );
};
export default ListPackages;
