import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
    Card,
    Container,
    Col,
    Row,
    Button,
    Breadcrumb,
    Pagination,
} from "react-bootstrap";
import Question from "../Components/Question";
import {
    getOne,
    getSums,
    updateAnswer,
    completeExamAttempt,
    getExamAttempt,
} from "../Store/Actions/Admin/Exams";
import RequestStatus from "../Components/RequestStatus";
//import axiosInstance from "../Util/axios";
//import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import ExamTimer from "../Components/ExamTimer";

const ExamAttempts = () => {
    // const [error, setError] = useState(null);
    const [duration, setDuration] = useState(0);
    const { id, examAttemptId } = useParams();
    const [alertStatus, setAlertStatus] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [isExamCompleted, setIsExamCompleted] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({});
    //const [examAttempt, setExamAttempt] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(0);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const examState = useSelector((state) => state.exams);
    const exam = examState.exam;

    let examAttempt = examState.examAttempt; //JSON.parse(localStorage.getItem("examAttempt"));

    let errors = [];
    if (typeof examState.errors !== "undefined") {
        examState.errors.map((item) => (errors[item.param] = item.msg));
    }

    const forceRerender = () => {
        setForceUpdate((prev) => prev + 1); // Trigger a re-render
    };

    useEffect(() => {
        /* if (!examAttempt) {
            console.log("FETCHING");
            axiosInstance()
                .post("student/get-exam-attempt", { examAttemptId })
                .then((response) => {
                    if (response.status === 200) {
                        examAttempt = response.data.object;
                        localStorage.setItem(
                            "examAttempt",
                            JSON.stringify(examAttempt)
                        );
                        console.log("FETCH COMPLETE", examAttempt);
                        setForceUpdate((prev) => prev + 1);

                        if (examAttempt.isCompleted === 1) {
                            setIsExamCompleted(true);
                            navigate(
                                `/exam-attempt-details/${exam.id}/${examAttempt.id}`
                            );
                        }
                    }
                })
                .catch((error) => setError(error));
        } */
        if (!examAttempt) {
            dispatch(getExamAttempt({ examAttemptId }));
        }
    }, [examAttempt, examAttemptId]);

    const handleAnswerSelected = (sumId, answerOption) => {
        //console.log("User Answers :", sumId, ":", answerOption);
        setSelectedOptions({
            ...selectedOptions,
            [sumId]: answerOption,
        });
        const data = {
            examId: exam.id,
            examAttemptId: examAttempt.id,
            sumId: sumId,
            selectedOption: answerOption,
        };
        dispatch(updateAnswer(data));
    };

    const handleNextQuestion = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } /* else {
            //setIsExamCompleted(true);
        } */
    };

    const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleSelectQuestion = (index) => {
        setCurrentQuestion(index);
    };

    const handleTimeUp = () => {
        setIsExamCompleted(true);
        submitExam();
    };

    useEffect(() => {
        setAlertStatus(true);

        dispatch(getOne({ id }));
        dispatch(getSums({ examId: id }));
    }, [dispatch, examAttemptId, id]);

    useEffect(() => {
        setQuestions(examState.sums);
    }, [examState.sums]);

    const submitExam = (e) => {
        if (e) {
            e.preventDefault();
        }
        setAlertStatus(true);
        if (examAttempt) {
            const data = {
                examId: id,
                examAttemptId: examAttempt.id,
            };
            dispatch(completeExamAttempt(data));
            navigate(`/exam-attempt-details/${exam.id}/${examAttempt.id}`);
        }
    };

    const questionForm = () => {
        if (questions.length) {
            const sum = questions[currentQuestion];
            const userSelectedOption = selectedOptions[sum.id];
            return (
                <Card className="shadow">
                    <Card.Body>
                        <div className="timer-container">
                            <ExamTimer
                                exam={exam}
                                examAttempt={examAttempt}
                                duration={duration}
                                onTimeUp={handleTimeUp}
                            />
                        </div>

                        <Question
                            sum={sum}
                            onSelectAnswer={handleAnswerSelected}
                            questionNo={currentQuestion}
                            selectedOption={userSelectedOption}
                            handleNextQuestion={handleNextQuestion}
                        />
                    </Card.Body>
                </Card>
            );
        }
    };

    const renderPagination = () => {
        if (questions.length) {
            return (
                <div className="pagination-container">
                    <Pagination>
                        <Pagination.Prev onClick={handlePreviousQuestion} />
                        {questions.map((_, index) => (
                            <Pagination.Item
                                key={index}
                                variant={
                                    index === currentQuestion
                                        ? "primary"
                                        : "secondary"
                                }
                                active={index === currentQuestion}
                                onClick={() => handleSelectQuestion(index)}
                                className="mr-2"
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next onClick={handleNextQuestion} />
                    </Pagination>
                </div>
            );
        }
    };

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/purchased-exams">Exams</Link>
                </li>
                <Breadcrumb.Item active>
                    {exam ? exam.name : ""}
                </Breadcrumb.Item>
            </Breadcrumb>
            {exam && examAttempt ? (
                <Container className="py-5">
                    <Row className="justify-content-center">
                        {examAttempt ? (
                            <Col md={8}>
                                {questionForm()}
                                <Row className="justify-content-center mt-4">
                                    <Col md={12} className="text-center">
                                        {renderPagination()}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        md={12}
                                        className="justify-content-center mt-4 text-center"
                                    >
                                        <Button
                                            type="button"
                                            variant="primary"
                                            onClick={submitExam}
                                        >
                                            Finish Exam
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            <>
                                <div className="text-center">
                                    <h5>Please Re-Attempt Exam</h5>
                                    <Link to="/purchased-exams">Back</Link>
                                </div>
                            </>
                        )}
                    </Row>
                </Container>
            ) : (
                <> Loading Exam Details</>
            )}

            {/* {error && error.response ? (
                <Alert variant="danger">{error.response}</Alert>
            ) : (
                <></>
            )} */}

            <div className="text-center">
                <RequestStatus
                    stateObj={examState}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
};

export default ExamAttempts;
