import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Breadcrumb, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import RequestStatus from "../../Components/RequestStatus";
import { SelectInput } from "../../Components/Input";
import {
    getExamsAssigned as getExamsAssignedToSchool,
    assignExams as assignExamsToSchool,
} from "../../Store/Actions/Admin/Exams";
import { getAll as getSchools } from "../../Store/Actions/Admin/Schools";
import { getAll as getAllCategories } from "../../Store/Actions/Admin/Category";
import { searchExams } from "../../Store/Actions/Admin/Exams";

import { MultiSelect } from "react-multi-select-component";
import { getOptions } from "../../Util/util";

import ExamCard from "../../Components/ExamsCard";

const AssignmentForm = ({
    exams,
    assignedExams,
    schools,
    errors,
    getExamsAssigned,
    categories,
    filterExamsByCategory,
    handleFormSubmit,
}) => {
    const [selectedExams, setSelectedExams] = useState([]);
    const [schoolId, setSchool] = useState(0);
    const [categoryId, setCategory] = useState(0);

    const getExamOptions = () => {
        const unassignedExams = exams.filter((pkg) => {
            return !assignedExams.some((assigned) => assigned.id === pkg.id);
        });

        const options = unassignedExams.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        return options;
    };

    const resetForm = (e) => {
        e.preventDefault();
        setSelectedExams([]);
        setSchool(0);
        setCategory(0);
    };

    const formSubmit = (e) => {
        e.preventDefault();
        const examIds = selectedExams.map((item) => item.value);

        const matchingExams = exams.filter((exam) => examIds.includes(exam.id));

        const filteredExams = matchingExams.map((exam) => ({
            id: exam.id,
            amount: exam.subscriptionCharges,
        }));

        const data = {
            schoolId,
            exams: filteredExams,
        };

        handleFormSubmit(data);
    };

    return (
        <>
            <Form onSubmit={formSubmit}>
                <Row>
                    <Col md={4}>
                        <SelectInput
                            errMessage={errors["schoolId"]}
                            name="schoolId"
                            placeholder="School"
                            label="School"
                            type="text"
                            Options={getOptions(schools, "name", "id")}
                            value={schoolId}
                            handleChange={(e) => {
                                setSchool(e.target.value);
                                getExamsAssigned(e.target.value);
                            }}
                            controlId="School"
                        />
                    </Col>

                    <Col md={4}>
                        <SelectInput
                            errMessage={errors["categoryId"]}
                            name="categoryId"
                            placeholder="Filter"
                            label="Filter Exams By Category"
                            type="text"
                            Options={getOptions(categories, "name", "id")}
                            value={categoryId}
                            handleChange={(e) => {
                                setCategory(e.target.value);
                                filterExamsByCategory(e.target.value);
                            }}
                            controlId="Category"
                        />
                    </Col>

                    <Col md={4}>
                        <label>Select Exams</label>
                        <MultiSelect
                            className="mt-2"
                            options={getExamOptions(exams, "name", "id")}
                            value={selectedExams}
                            onChange={(items) => {
                                setSelectedExams(items);
                            }}
                            labelledBy={"Select"}
                            isCreatable={true}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md="12" className="text-right">
                        <Button variant="primary" type="submit">
                            Assign Packages
                        </Button>{" "}
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={resetForm}
                        >
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const AssignExams = () => {
    const [alertStatus, setAlertStatus] = useState(true);

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.exams);
    const exams = stateObject.exams;
    const assignedExams = stateObject.assignedExams;

    const schools = useSelector((state) => state.schools.schools);
    const categories = useSelector((state) => state.categories.categories);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const getExamsAssigned = (schoolId) => {
        dispatch(getExamsAssignedToSchool({ schoolId: schoolId }));
    };

    const filterExamsByCategory = (categoryId) => {
        dispatch(searchExams({ categoryId, pagination: false }));
    };

    const handleFormSubmit = (data) => {
        dispatch(assignExamsToSchool(data));
    };

    useEffect(() => {
        dispatch(getSchools());
        dispatch(getAllCategories());
    }, []);

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <Breadcrumb.Item active>Assign Exams</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <AssignmentForm
                                exams={exams ?? []}
                                assignedExams={assignedExams ?? []}
                                categories={categories ?? []}
                                schools={schools ?? []}
                                errors={errors}
                                getExamsAssigned={getExamsAssigned}
                                filterExamsByCategory={filterExamsByCategory}
                                handleFormSubmit={handleFormSubmit}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {assignedExams.length > 0 ? (
                <>
                    <br />
                    <h5>Assigned Exams</h5>
                    <ExamCard exams={assignedExams} purchased={true} />
                </>
            ) : (
                ""
            )}
            <div className="text-center mt-5">
                <RequestStatus
                    stateObj={stateObject}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
};

export default AssignExams;
