import { takeLatest, put } from "redux-saga/effects";
import BaseSaga from "./Base";
import { StudentTypes } from "../Actions/Types/Students";
import { failed, getAll as getStudents } from "../Actions/Student";

const endPoint = "/student";

const sagaWatchers = BaseSaga(endPoint, failed);
const SagaActions = {
    ...sagaWatchers,
};

const refreshData = function* (action) {
    yield put(getStudents());
};

const updateProfile = function* (action) {
    yield sagaWatchers.update(action, endPoint);
};
export function* watchStudentRequests() {
    yield takeLatest(StudentTypes.CREATE, SagaActions.create);
    yield takeLatest(StudentTypes.UPDATE, SagaActions.update);
    yield takeLatest(StudentTypes.DELETE, SagaActions.remove);
    yield takeLatest(StudentTypes.GET_ALL, SagaActions.getAll);
    yield takeLatest(StudentTypes.GET, SagaActions.getOne);
    yield takeLatest(
        [StudentTypes.CREATE_SUCCESS, StudentTypes.UPDATE_SUCCESS],
        refreshData
    );
    yield takeLatest(StudentTypes.UPDATE_STUDENT_PROFILE, updateProfile);
}
