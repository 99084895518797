import getActions from "../Base";
import { ExamTypes } from "../Types/Exams";

const baseActions = getActions(ExamTypes);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const getSums = (data) => {
    return {
        type: ExamTypes["GET_SUMS"],
        payload: data,
        success: ExamTypes["GET_SUMS_SUCCESS"],
    };
};

const getSum = (data) => {
    return {
        type: ExamTypes["GET_SUM"],
        payload: data,
        success: ExamTypes["GET_SUM_SUCCESS"],
    };
};

const createSum = (data) => {
    return {
        type: ExamTypes["CREATE_SUM"],
        payload: data,
        success: ExamTypes["CREATE_SUM_SUCCESS"],
    };
};

const updateSum = (data) => {
    return {
        type: ExamTypes["UPDATE_SUM"],
        payload: data,
        success: ExamTypes["UPDATE_SUM_SUCCESS"],
    };
};

const getPublishedExams = (data) => {
    return {
        type: ExamTypes["GET_PUBLISHED_EXAMS"],
        payload: data,
        success: ExamTypes["GET_PUBLISHED_EXAMS_SUCCESS"],
    };
};

const getUnPublishedExams = (data) => {
    return {
        type: ExamTypes["GET_UNPUBLISHED_EXAMS"],
        payload: data,
        success: ExamTypes["GET_UNPUBLISHED_EXAMS_SUCCESS"],
    };
};

const getExamsByQueryParams = (data) => {
    return {
        type: ExamTypes["GET_EXAMS_BY_QUERY_STRING"],
        payload: data,
        success: ExamTypes["GET_EXAMS_BY_QUERY_STRING_SUCCESS"],
    };
};

const deleteSum = (data) => {
    return {
        type: ExamTypes["DELETE_SUM"],
        payload: data,
        success: ExamTypes["DELETE_SUM_SUCCESS"],
    };
};

const searchExams = (data) => {
    return {
        type: ExamTypes["SEARCH_EXAMS"],
        payload: data,
        success: ExamTypes["SEARCH_EXAMS_SUCCESS"],
    };
};

const getUserExams = (data) => {
    return {
        type: ExamTypes["GET_USER_EXAMS"],
        payload: data,
        success: ExamTypes["GET_USER_EXAMS_SUCCESS"],
    };
};

const purchaseExam = (data) => {
    return {
        type: ExamTypes["PURCHASE_EXAMS"],
        payload: data,
        success: ExamTypes["PURCHASE_EXAMS_SUCCESS"],
    };
};

const attemptExam = (data) => {
    return {
        type: ExamTypes["EXAM_ATTEMPT"],
        payload: data,
        success: ExamTypes["EXAM_ATTEMPT_SUCCESS"],
    };
};

const resetExamAttempt = (data) => {
    return {
        type: ExamTypes["RESET_EXAM_ATTEMPT_STATE"],
        payload: data,
        success: ExamTypes["RESET_EXAM_ATTEMPT_STATE_SUCCESS"],
    };
};

const getExamAttempt = (data) => {
    return {
        type: ExamTypes["GET_EXAM_ATTEMPT"],
        payload: data,
        success: ExamTypes["GET_EXAM_ATTEMPT_SUCCESS"],
    };
};

const updateAnswer = (data) => {
    return {
        type: ExamTypes["UPDATE_QUESTION_ANSWER"],
        payload: data,
        success: ExamTypes["UPDATE_QUESTION_ANSWER_SUCCESS"],
    };
};

const completeExamAttempt = (data) => {
    return {
        type: ExamTypes["COMPLETE_EXAM_ATTEMPT"],
        payload: data,
        success: ExamTypes["COMPLETE_EXAM_ATTEMPT_SUCCESS"],
    };
};

const getExamResult = (data) => {
    return {
        type: ExamTypes["GET_EXAM_ATTEMPT_RESULT"],
        payload: data,
        success: ExamTypes["GET_EXAM_ATTEMPT_RESULT_SUCCESS"],
    };
};

const getAllExamsAttempted = () => {
    return {
        type: ExamTypes["GET_ALL_EXAMS_ATTEMPTED"],
        payload: null,
        success: ExamTypes["GET_ALL_EXAMS_ATTEMPTED_SUCCESS"],
    };
};

const getExamsAssigned = (data) => {
    return {
        type: ExamTypes["GET_EXAMS_ASSIGNGED_TO_SCHOOL"],
        payload: data,
        success: ExamTypes["GET_EXAMS_ASSIGNGED_TO_SCHOOL_SUCCESS"],
    };
};

const assignExams = (data) => {
    return {
        type: ExamTypes["ASSIGN_EXAMS_TO_SCHOOL"],
        payload: data,
        success: ExamTypes["ASSIGN_EXAMS_TO_SCHOOL_SUCCESS"],
    };
};

const uploadSums = (data) => {
    return {
        type: ExamTypes["UPLOAD_SUMS"],
        payload: data,
        success: ExamTypes["UPLOAD_SUMS_SUCCESS"],
    };
};

const downloadCSVRequest = (data) => ({
    type: ExamTypes["DOWNLOAD_CSV"],
    payload: data,
    success: ExamTypes["DOWNLOAD_CSV_SUCCESS"],
});

const downloadCSVSuccess = (data) => ({
    type: ExamTypes["DOWNLOAD_CSV_SUCCESS"],
    payload: data,
});

export {
    create,
    update,
    remove,
    getAll,
    getOne,
    getSums,
    getSum,
    createSum,
    updateSum,
    deleteSum,
    failed,
    getPublishedExams,
    getUnPublishedExams,
    getExamsByQueryParams,
    searchExams,
    purchaseExam,
    getUserExams,
    attemptExam,
    updateAnswer,
    completeExamAttempt,
    getExamResult,
    getExamAttempt,
    resetExamAttempt,
    getAllExamsAttempted,
    getExamsAssigned,
    assignExams,
    uploadSums,
    downloadCSVRequest,
    downloadCSVSuccess,
};
