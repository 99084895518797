import { takeLatest, put } from "redux-saga/effects";
import BaseSaga from "../Base";
import { PackageTypes as ActionConstant } from "../../Actions/Types/Packages";
import { failed, getAll as getAllPackages } from "../../Actions/Admin/Packages";

const message = "Request Failed!";
let err = new Error(message);
err.status = 500;

const endPoint = "/admin/package";

const sagaWatchers = BaseSaga(endPoint, failed);

const getPublishedPackages = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/get-published-packages");
};

const getUnPublishedPackages = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/get-unpublished-packages");
};

const SagaActions = {
    ...sagaWatchers,
};

const refreshData = function* (action) {
    yield put(getAllPackages());
};

const searchPackages = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/search");
};

const assignPackagesToSchool = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/assign-packages");
};

const getPackagesAssignedToSchool = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/get-assigned-packages");
};

const purchasePackage = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/purchase");
};

const getUserPackages = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/get-user-packages");
};

export function* watchPackageRequests() {
    yield takeLatest(ActionConstant.CREATE, SagaActions.create);
    yield takeLatest(ActionConstant.UPDATE, SagaActions.update);
    yield takeLatest(ActionConstant.DELETE, SagaActions.remove);
    yield takeLatest(ActionConstant.GET_ALL, SagaActions.getAll);
    yield takeLatest(ActionConstant.GET, SagaActions.getOne);
    yield takeLatest(
        ActionConstant.GET_PUBLISHED_PACKAGES,
        getPublishedPackages
    );
    yield takeLatest(
        ActionConstant.GET_UNPUBLISHED_PACKAGES,
        getUnPublishedPackages
    );
    yield takeLatest(ActionConstant.SEARCH_PACKAGES, searchPackages);

    yield takeLatest(
        [ActionConstant.CREATE_SUCCESS, ActionConstant.UPDATE_SUCCESS],
        refreshData
    );
    yield takeLatest(ActionConstant.PURCHASE_PACKAGES, purchasePackage);
    yield takeLatest(ActionConstant.GET_USER_PACKAGES, getUserPackages);
    yield takeLatest(
        ActionConstant.ASSIGN_PACKAGES_TO_SCHOOL,
        assignPackagesToSchool
    );
    yield takeLatest(
        ActionConstant.GET_PACKAGES_ASSIGNED_TO_SCHOOL,
        getPackagesAssignedToSchool
    );
}
