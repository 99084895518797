import { useDispatch } from "react-redux";
import { getAuthenticatedUser } from "../Store/Actions/Auth";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../Util/axios";
const { Row, Card, Col, Button } = require("react-bootstrap");
const {
    purchaseExam,
    resetExamAttempt,
} = require("../Store/Actions/Admin/Exams");

const ExamCard = (props) => {
    const navigate = useNavigate();
    const user = getAuthenticatedUser();
    const dispatch = useDispatch();
    const buyExam = (exam) => {
        const data = {
            examId: exam.id,
            name: exam.name,
            amount: exam.subscriptionCharges,
            createdDate: new Date(),
            ...props.search_state,
        };
        dispatch(purchaseExam(data));
    };

    const handleExamAttempt = (exam) => {
        try {
            const response = axiosInstance()
                .post("student/attempt-exam", {
                    examId: exam.id,
                })
                .then((response) => {
                    console.log("FROM EXAM PAGE", response);
                    if (response.status === 200) {
                        const examAttempt = response.data.object;
                        /*  localStorage.removeItem("examAttempted");
                        localStorage.setItem(
                            "examAttempted",
                            JSON.stringify(examAttempt)
                        ); */
                        dispatch(resetExamAttempt(response.data));
                        // Adjust this condition based on your action's response
                        navigate(`/attempt-exam/${exam.id}/${examAttempt.id}`); // Navigate to the next page after successful exam attempt
                    } else {
                        console.error("Error attempting exam:", response.error); // Handle the error
                    }
                })
                .catch((error) => {
                    throw error;
                });
        } catch (error) {
            console.error("An error occurred:", error); // Handle the error
        }
    };

    const getStudentActions = (exam) => {
        if (user.role === "student" && props.purchased) {
            return (
                <Button
                    type="button"
                    variant="success"
                    onClick={() => handleExamAttempt(exam)}
                >
                    Attempt Exam
                </Button>
            );
        }
        return <></>;
    };
    return (
        <Row className="listing">
            {props.exams.map((item, index) => {
                return (
                    <Col md={3} key={index}>
                        <Card border="light" bg="light">
                            <Card.Header
                                bg="primary"
                                className="bg-secondary text-white"
                            >
                                {item.name}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>{item.description}</Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col xs={3} md={4}>
                                        Category :
                                    </Col>
                                    <Col xs={9} md={4}>
                                        {item.category.name}
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={4} md={4}>
                                        Price :
                                    </Col>
                                    <Col xs={6} md={4}>
                                        Rs. {item.subscriptionCharges}/-
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={4} md={4}>
                                        Duration :
                                    </Col>
                                    <Col xs={6} md={4}>
                                        {item.totalTime} min
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col xs={4} md={4}>
                                        Sums :
                                    </Col>
                                    <Col xs={6} md={4}>
                                        {item.sumCount}
                                    </Col>
                                </Row>
                            </Card.Body>
                            <div className="d-grid mt-2 ">
                                {props.purchased ? (
                                    ""
                                ) : (
                                    <Button
                                        variant="success"
                                        size="xs"
                                        type="button"
                                        onClick={() => {
                                            buyExam(item);
                                        }}
                                    >
                                        Buy
                                    </Button>
                                )}
                                {getStudentActions(item)}
                            </div>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

export default ExamCard;
