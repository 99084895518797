import { AuthTypes } from "../Actions/Types/Auth";
const initState = {
    user: {},
    authFailure: false,
    authenticated: false,
    message: "",
    access_token: "",
    refresh_token: "",
};

const AuthReducer = (state = initState, action) => {
    switch (action.type) {
        case AuthTypes.LOGIN_REQUEST:
            state = {
                ...state,
                message: "",
                authenticating: true,
            };
            break;
        case AuthTypes.LOGIN_SUCCESS:
            state = {
                ...initState,
                user: action.payload.user,
                message: action.payload.message,
                authenticated: true,
                access_token: action.payload.access_token,
                refresh_token: action.payload.refresh_token,
            };
            break;
        case AuthTypes.LOGIN_ERROR:
            state = {
                ...initState,
                message: action.payload.message,
                authFailure: true,
            };
            break;
        case AuthTypes.LOGOUT_SUCCESS:
            state = {
                ...initState,
                message: action.payload.message,
                authenticated: false,
            };
            break;
        default:
            return state;
    }
    return state;
};
export default AuthReducer;
