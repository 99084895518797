import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Button, Breadcrumb, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Utils from "../../../Util/util";
import DataTable from "../../../Components/DataTable";
import RequestStatus from "../../../Components/RequestStatus";
import { getAll, getOne } from "../../../Store/Actions/Admin/Category";
import NewCategory from "./NewCategory";
import EditCategory from "./EditCategory";

const ListCategories = (props) => {
    const categoryState = useSelector((state) => state.categories);
    const dispatch = useDispatch();

    const getCategory = (id) => {
        dispatch(getOne({ id }));
    };

    const actionButtons = (cell, row) => {
        return (
            <>
                <ButtonGroup aria-label="Actions" size="sm" vertical>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                            getCategory(row.id);
                            props.setEditDialog(true);
                        }}
                    >
                        <i className="fa fa-edit" aria-hidden="true" /> Edit
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    const columns = [
        {
            dataField: "name",
            text: "Name",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "description",
            text: "Description",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
        {
            dataField: "#",
            text: "...",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    return (
        <div>
            <DataTable
                keyField="id"
                columns={columns}
                data={categoryState.categories ?? []}
                search={true}
                pagination={true}
                button={false}
                dialogButton={true}
                dialogAction={props.setDialog}
                buttonLabel="Add New"
                className="transaction-table"
            />
        </div>
    );
};

const Categories = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [showDialog, setDialog] = useState(false);
    const [showEditDialog, setEditDialog] = useState(false);

    const stateObject = useSelector((state) => state.categories);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <Breadcrumb.Item active>Category</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <NewCategory
                                setAlertStatus={setAlertStatus}
                                showDialog={showDialog}
                                setDialog={setDialog}
                            />

                            <EditCategory
                                setAlertStatus={setAlertStatus}
                                showDialog={showEditDialog}
                                setDialog={setEditDialog}
                            />
                            <ListCategories setDialog={setDialog} setEditDialog={setEditDialog} />
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default Categories;
