import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    Col,
    Row,
    Button,
    ButtonGroup,
    Breadcrumb,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as Utils from "../../Util/util";
import DataTable from "../../Components/DataTable";
import RequestStatus from "../../Components/RequestStatus";
import {
    getSums,
    getSum,
    deleteSum,
    downloadCSVRequest,
} from "../../Store/Actions/Admin/Exams";
import NewSum from "./NewSum";
import { Link } from "react-router-dom";

import EditSum from "./EditSum";
import UploadSums from "./UploadSums";

const ListSums = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const examState = useSelector((state) => state.exams);
    const dispatch = useDispatch();

    const loadSum = (id) => {
        dispatch(getSum({ id, examId: props.examId }));
    };

    const removeSum = (id) => {
        dispatch(deleteSum({ id, examId: props.examId }));
    };

    const handleDownload = async () => {
        dispatch(downloadCSVRequest({ examId: props.examId }));
    };

    const actionButtons = (cell, row) => {
        return (
            <>
                {row.status == 1 ? (
                    <ButtonGroup aria-label="Actions">
                        <Button
                            variant="primary"
                            onClick={() => {
                                loadSum(row.id);
                                props.setEditDialog(true);
                            }}
                        >
                            <i
                                className="fa fa-edit fa-lg"
                                aria-hidden="true"
                            />
                            Edit
                        </Button>

                        <Button
                            variant="danger"
                            onClick={() => {
                                removeSum(row.id);
                            }}
                        >
                            <i
                                className="fa fa-trash fa-lg"
                                aria-hidden="true"
                            />{" "}
                            Delete
                        </Button>
                    </ButtonGroup>
                ) : (
                    ""
                )}
            </>
        );
    };

    const columns = [
        {
            dataField: "question",
            text: "Question",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },

        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "creator.name",
            text: "Created By",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },

        {
            dataField: "updatedAt",
            text: "Last Updated",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
        {
            dataField: "#",
            text: "...",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    const formActionButtons = () => {
        return (
            <>
                <Button
                    variant="info"
                    size="md"
                    type="button"
                    onClick={handleDownload}
                    className="ml-5 mr-5"
                >
                    {examState.downloading ? "Downloading..." : "Download CSV"}
                </Button>

                <Button
                    variant="warning"
                    size="md"
                    type="button"
                    onClick={() => setModalShow(true)}
                >
                    Upload Sums
                </Button>
            </>
        );
    };

    return (
        <div>
            <UploadSums
                examId={props.examId}
                show={modalShow}
                onHide={() => setModalShow(false)}
                setAlertStatus={props.setAlertStatus}
            />
            <DataTable
                actionButtons={formActionButtons}
                keyField="id"
                columns={columns}
                data={examState.sums ?? []}
                search={true}
                pagination={true}
                button={false}
                dialogButton={true}
                dialogAction={props.setDialog}
                buttonLabel="Add New"
                className="transaction-table"
            />
        </div>
    );
};

const Sums = (props) => {
    const { id } = useParams();
    const [examId, setExamId] = useState(0);
    const [alertStatus, setAlertStatus] = useState(true);
    const [showDialog, setDialog] = useState(false);
    const [showEditDialog, setEditDialog] = useState(false);

    const stateObject = useSelector((state) => state.exams);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    console.log(stateObject);

    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            setExamId(id);
            dispatch(getSums({ examId: id }));
        }
    }, [dispatch]);

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/exams">Exam</Link>
                </li>
                <Breadcrumb.Item active>Sums</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <NewSum
                                setAlertStatus={setAlertStatus}
                                showDialog={showDialog}
                                setDialog={setDialog}
                                examId={examId}
                            />

                            <EditSum
                                setAlertStatus={setAlertStatus}
                                showDialog={showEditDialog}
                                setDialog={setEditDialog}
                                examId={examId}
                            />
                            <ListSums
                                setDialog={setDialog}
                                examId={examId}
                                setEditDialog={setEditDialog}
                                setAlertStatus={setAlertStatus}
                            />
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default Sums;
