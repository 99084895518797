import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Offcanvas, Form } from "react-bootstrap";
import { Input, TextArea, CheckBoxInput } from "../../../Components/Input";
import { update } from "../../../Store/Actions/Admin/Category";

const EditCategory = (props) => {
    const [state, setState] = useState({
        id: "",
        name: "",
        description: "",
        fromAge: "",
        toAge: "",
        status: 0,
    });

    const stateObject = useSelector((state) => state.categories);
    const category = stateObject.categoryObj;

    const setFormInitState = () => {
        console.log(category);
        if (category) {
            setState({
                id: category.id,
                name: category["name"] ?? "",
                description: category["description"] ?? "",
                fromAge: category["fromAge"] ?? 0,
                toAge: category["toAge"] ?? 0,
                status: category["status"] ?? 0,
            });
        }
    };

    useEffect(() => {
        setFormInitState();
    }, [category]);

    const resetForm = (e) => {
        setFormInitState();
    };

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const data = { ...state };
        dispatch(update(data));
    };

    return (
        <Offcanvas
            placement="end"
            backdrop="true"
            show={props.showDialog}
            onHide={props.setDialog}
            {...props}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Exam</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Name"
                                value={state.name}
                                onChange={(e) => onChangeHandler("name", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <TextArea
                                type="text"
                                name="description"
                                label="Description"
                                placeholder="Description"
                                value={state.description}
                                onChange={(e) => onChangeHandler("description", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6}>
                            <Input
                                type="number"
                                name="fromAge"
                                label="From Age"
                                placeholder="From Age"
                                value={state.fromAge}
                                onChange={(e) => onChangeHandler("fromAge", e.target.value)}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                type="number"
                                name="toAge"
                                label="To Age"
                                placeholder="To Age"
                                value={state.toAge}
                                onChange={(e) => onChangeHandler("toAge", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md={6}>
                            <span style={{ display: "inline-block", float: "left", margin: "5px" }}>
                                <CheckBoxInput
                                    value={state.status}
                                    label="Activate"
                                    checked={state.status ?? false}
                                    onClick={(e) => onChangeHandler("status", e.target.checked)}
                                />
                            </span>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md="12" className="mt-2 text-middle">
                            <Button variant="primary" type="Submit">
                                Update
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={resetForm} type="Button">
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default EditCategory;
