import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Offcanvas, Form } from "react-bootstrap";
import { Input, TextArea, CheckBoxInput } from "../../Components/Input";
import { update } from "../../Store/Actions/Admin/Schools";

const EditSchool = (props) => {
    const [state, setState] = useState({
        id: 0,
        name: "",
        address: "",
        state: "",
        city: "",
        pinCode: "",
        contactPerson: "",
        email: "",
        phoneNo: "",
        userName: "",
        password: "",
        createdDate: new Date(),
        status: 0,
    });

    const stateObject = useSelector((state) => state.schools);
    const school = stateObject.school;

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const setFormInitState = () => {
        if (school) {
            setState({
                id: school.id,
                name: school.name,
                address: school.address,
                state: school.address,
                city: school.city,
                pinCode: school.pinCode,
                contactPerson: school.contactPerson,
                email: school.email,
                phoneNo: school.phoneNo,
                userName: school.userName,
                password: school.password,
                status: school.status ?? 0,
                updatedAt: new Date(),
            });
        }
    };

    useEffect(() => {
        setFormInitState();
    }, [school]);

    const resetForm = (e) => {
        setFormInitState();
    };

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const data = { ...state };
        console.clear();
        console.log(data);
        dispatch(update(data));
    };

    return (
        <Offcanvas
            placement="end"
            backdrop="true"
            show={props.showDialog}
            onHide={props.setDialog}
            {...props}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Update School</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Name"
                                value={state.name}
                                onChange={(e) => onChangeHandler("name", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <TextArea
                                type="text"
                                name="address"
                                label="Address"
                                placeholder="Address"
                                value={state.address}
                                onChange={(e) => onChangeHandler("address", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="state"
                                label="State"
                                placeholder="State"
                                value={state.state}
                                onChange={(e) => onChangeHandler("state", e.target.value)}
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="city"
                                label="City"
                                placeholder="City"
                                value={state.city}
                                onChange={(e) => onChangeHandler("city", e.target.value)}
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="number"
                                name="pinCode"
                                label="Pin Code"
                                placeholder="Pin Code"
                                value={state.pinCode}
                                onChange={(e) => onChangeHandler("pinCode", e.target.value)}
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="contactPerson"
                                label="Contact Person"
                                placeholder="Contact Person"
                                value={state.contactPerson}
                                onChange={(e) => onChangeHandler("contactPerson", e.target.value)}
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="phoneNo"
                                label="Phone Number"
                                placeholder="Phone Number"
                                value={state.phoneNo}
                                onChange={(e) => onChangeHandler("phoneNo", e.target.value)}
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="email"
                                name="email"
                                label="Email"
                                placeholder="Email"
                                value={state.email}
                                onChange={(e) => onChangeHandler("email", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6}>
                            <span style={{ display: "inline-block", float: "left", margin: "5px" }}>
                                <CheckBoxInput
                                    value={state.status}
                                    label="Activate"
                                    checked={state.status ?? false}
                                    onClick={(e) => onChangeHandler("status", e.target.checked)}
                                />
                            </span>
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md="12" className="mt-2 text-middle">
                            <Button variant="primary" type="Submit">
                                Update
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={resetForm} type="Button">
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default EditSchool;
