import React, { useEffect, useState } from "react";
import Sidebar from "./Components/SideNavBar";
import Navbar from "./Components/Header";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "./Store/Actions/Auth";

export default function App() {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        //If User is not authenticated it will check for data in local storage
        //if it's already true condition will eval to false and fucntion will not execute
        if (!auth.authenticated) dispatch(isAuthenticated());
    }, [dispatch]);

    const [open, setOpen] = useState(true);

    const toggleSidebar = () => {
        setOpen(!open);
    };
    return (
        <div className="App">
            <Navbar toggleSidebar={toggleSidebar} />
            <div className="app-content">
                <Sidebar open={open} />
                <div className="content">
                    <Outlet></Outlet>
                </div>
            </div>
        </div>
    );
}
