import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import ClassReducer from "./Admin/ClassReducer";
import ExamReducer from "./Admin/ExamsReducer";
import SchoolsReducer from "./Admin/SchoolsReducer";
import PackageReducer from "./Admin/PackageReducer";
import CategoryReducer from "./Admin/CategoryReducer";
import StudentReducer from "./StudentReducer";
import DashboardReducer from "./DashboardReducer";

const RootReducer = combineReducers({
    auth: AuthReducer,
    classes: ClassReducer,
    exams: ExamReducer,
    schools: SchoolsReducer,
    packages: PackageReducer,
    categories: CategoryReducer,
    students: StudentReducer,
    dashboards: DashboardReducer,
});

export default RootReducer;
