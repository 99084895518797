import {
    Col,
    Button,
    Row,
    Container,
    Card,
    Form,
    Alert,
} from "react-bootstrap";
import Logo from "../Components/logo.png";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../Store/Actions/Auth";
import { useNavigate } from "react-router-dom";
import { Preloader, Input } from "../Components/Input";

export default function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const auth = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const userSignIn = (e) => {
        e.preventDefault();
        const user = {
            username,
            password,
        };
        dispatch(login(user));
    };

    if (auth.authenticated) {
        return navigate("/");
    }
    const getMessage = () => {
        if (auth.authenticating) {
            return <Preloader />;
        }
        if (auth.authFailure && auth.message) {
            return <Alert variant="danger">{auth.message}</Alert>;
        }
        if (auth.authenticated) {
            return <Alert variant="success">{auth.message}</Alert>;
        }
    };

    return (
        <div>
            <Container className="bg-gray">
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col md={4} xs={4}>
                        <h2 className="fw-bold mb-2 text-center">
                            <img
                                src={Logo}
                                alt="Abacus"
                                width="80%"
                                height="80px"
                            />
                        </h2>
                        <div className="pt-3"></div>
                        {/* <div className="border border-3 border-primary"></div> */}
                        <Card className="shadow">
                            <Card.Body>
                                <div className="ant-card-head-title mb-3 mt-3">
                                    <h3 className="ant-typography css-qgg3xn login-header">
                                        Sign in to your account
                                    </h3>
                                </div>
                                <div className="mb-3">
                                    <br />
                                    <div className="mb-3 mt-md-8">
                                        <Form onSubmit={userSignIn}>
                                            <Input
                                                name="email"
                                                value={username}
                                                placeholder="Enter mobile number "
                                                errMessage=""
                                                type="text"
                                                controlId="formUserName"
                                                icon="fa fa-user fa-lg"
                                                onChange={(e) =>
                                                    setUsername(e.target.value)
                                                }
                                            />
                                            <div className="pt-4"></div>

                                            <Input
                                                name="password"
                                                placeholder="Password"
                                                errMessage=""
                                                value={password}
                                                type="password"
                                                controlId="formPassword"
                                                icon="fa fa-lock fa-lg"
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                            />
                                            <div className="pt-3"></div>
                                            <Form.Group
                                                className="mb-3 text-end"
                                                controlId="formBasicCheckbox"
                                            >
                                                <p
                                                    className="small text-left"
                                                    style={{ float: "left" }}
                                                >
                                                    <a
                                                        className="text-primary"
                                                        href="/home"
                                                    >
                                                        Go Back
                                                    </a>
                                                </p>
                                                <p className="small text-right">
                                                    <a
                                                        className="text-primary"
                                                        href="#!"
                                                    >
                                                        Forgot password?
                                                    </a>
                                                </p>
                                            </Form.Group>
                                            <div className="pt-3"></div>
                                            <div className="d-grid">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                >
                                                    Login
                                                </Button>
                                            </div>
                                        </Form>
                                        {/* <div className="mt-3">
                                            <p className="mb-0  text-center">
                                                Don't have an account?{" "}
                                                <a href="{''}" className="text-primary fw-bold">
                                                    Sign Up
                                                </a>
                                            </p>
                                        </div> */}
                                        <br />
                                        <div align="center">{getMessage()}</div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
