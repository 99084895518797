import { StudentTypes as ActionConstant } from "../Actions/Types/Students";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    studentId: 0,
    students: [],
    student: null,
};

const SchoolsReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.CREATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.UPDATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.UPDATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.DELETE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET:
            state = {
                ...state,
                student: null,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                student: action.payload.object,
            };
            break;
        case ActionConstant.UPDATE_STUDENT_PROFILE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.UPDATE_STUDENT_PROFILE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                student: action.payload.object,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET_ALL:
            state = {
                ...state,
                PROCESSING: true,
                students: [],
            };
            break;
        case ActionConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                students: action.payload.objects,
            };
            break;

        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default SchoolsReducer;
