import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Offcanvas, Form } from "react-bootstrap";
import { Input, TextArea } from "../../Components/Input";
import { create } from "../../Store/Actions/Admin/Schools";

const NewSchool = (props) => {
    const [state, setState] = useState({
        name: "",
        address: "",
        state: "",
        city: "",
        pinCode: "",
        contactPerson: "",
        email: "",
        phoneNo: "",
        userName: "",
        password: "",
        createdDate: new Date(),
    });

    const stateObject = useSelector((state) => state.schools);

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const data = { ...state };
        dispatch(create(data));
    };

    const resetForm = (e) => {
        e.preventDefault();
        setState({
            name: "",
            address: "",
            state: "",
            city: "",
            pinCode: "",
            email: "",
            contactPerson: "",
            phoneNo: "",
            userName: "",
            password: "",
            createdDate: new Date(),
        });
    };

    return (
        <Offcanvas
            placement="end"
            backdrop="true"
            show={props.showDialog}
            onHide={props.setDialog}
            {...props}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add School</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Name"
                                value={state.name}
                                onChange={(e) =>
                                    onChangeHandler("name", e.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <TextArea
                                type="text"
                                name="address"
                                label="Address"
                                placeholder="Address"
                                value={state.address}
                                onChange={(e) =>
                                    onChangeHandler("address", e.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="state"
                                label="State"
                                placeholder="State"
                                value={state.state}
                                onChange={(e) =>
                                    onChangeHandler("state", e.target.value)
                                }
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="city"
                                label="City"
                                placeholder="City"
                                value={state.city}
                                onChange={(e) =>
                                    onChangeHandler("city", e.target.value)
                                }
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="number"
                                name="pinCode"
                                label="Pin Code"
                                placeholder="Pin Code"
                                value={state.pinCode}
                                onChange={(e) =>
                                    onChangeHandler("pinCode", e.target.value)
                                }
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="contactPerson"
                                label="Contact Person"
                                placeholder="Contact Person"
                                value={state.contactPerson}
                                onChange={(e) =>
                                    onChangeHandler(
                                        "contactPerson",
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="phoneNo"
                                label="Phone Number"
                                placeholder="Phone Number"
                                value={state.phoneNo}
                                onChange={(e) =>
                                    onChangeHandler("phoneNo", e.target.value)
                                }
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="email"
                                name="email"
                                label="Email"
                                placeholder="Email"
                                value={state.email}
                                onChange={(e) =>
                                    onChangeHandler("email", e.target.value)
                                }
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="username"
                                label="Username"
                                placeholder="Username"
                                value={state.userName}
                                onChange={(e) =>
                                    onChangeHandler("userName", e.target.value)
                                }
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="password"
                                name="password"
                                label="Password"
                                placeholder="Password"
                                value={state.password}
                                onChange={(e) =>
                                    onChangeHandler("password", e.target.value)
                                }
                            />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md="12" className="mt-2 text-middle">
                            <Button variant="primary" type="Submit">
                                Create
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant="secondary"
                                onClick={resetForm}
                                type="Button"
                            >
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default NewSchool;
