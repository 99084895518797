import React from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "./logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Store/Actions/Auth";

const NavbarComponent = ({ toggleSidebar }) => {
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const LogOut = () => {
        dispatch(logout());
    };

    if (!auth.authenticated) {
        navigate("/home");
    }

    return (
        <Navbar bg="dark" variant="dark" className="zigzag-outside">
            <Container fluid>
                <Button
                    onClick={toggleSidebar}
                    className="navbar-toggle"
                    variant="link"
                >
                    <FontAwesomeIcon icon={faBars} />
                </Button>
                <Navbar.Brand href="/">
                    <img src={Logo} alt="Abacus" className="nav-logo" />
                </Navbar.Brand>
                <div className="d-flex justify-content-end w-100">
                    <div className="ml-5">
                        <Nav.Item className="nav-user">
                            <Button
                                className="text-decoration-none"
                                variant="link"
                            >
                                Welcome : {auth.user.name},
                            </Button>
                        </Nav.Item>
                    </div>
                    <div>
                        <Nav.Item>
                            <Button
                                variant="link"
                                className="text-decoration-none"
                                onClick={LogOut}
                            >
                                <i
                                    className="fa fa-sign-out"
                                    aria-hidden="true"
                                ></i>{" "}
                                Logout
                            </Button>
                        </Nav.Item>
                    </div>
                </div>
            </Container>
        </Navbar>
    );
};

export default NavbarComponent;
