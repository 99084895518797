import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Button, Breadcrumb, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Utils from "../../../Util/util";
import DataTable from "../../../Components/DataTable";
import RequestStatus from "../../../Components/RequestStatus";
import { getAll, getOne } from "../../../Store/Actions/Admin/Exams";
import { getAll as getAllCategories } from "../../../Store/Actions/Admin/Category";
import NewExam from "./NewExam";
import EditExam from "./EditExam";

const ListExams = (props) => {
    const examState = useSelector((state) => state.exams);
    const dispatch = useDispatch();

    const getExam = (id) => {
        dispatch(getOne({ id }));
    };

    const actionButtons = (cell, row) => {
        return (
            <>
                <ButtonGroup aria-label="Actions" size="sm" vertical>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                            getExam(row.id);
                            props.setEditDialog(true);
                        }}
                    >
                        <i className="fa fa-edit" aria-hidden="true" /> Edit
                    </Button>
                    {/* <Button variant="danger">
                        <i className="fa fa-trash fa-lg" aria-hidden="true" /> Delete
                    </Button> */}
                    <Button variant="success" size="sm" as={Link} to={`/exams/${row.id}/sums`}>
                        <i className="fa fa-puzzle-piece" aria-hidden="true" /> Sums
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    const columns = [
        {
            dataField: "name",
            text: "Name",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "category.name",
            text: "Category",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },

        {
            dataField: "isPremium",
            text: "Premium",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showPremiumBadge,
        },
        {
            dataField: "subscriptionCharges",
            text: "Charges",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "totalTime",
            text: "Duration",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "isPublished",
            text: "Published",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showPublishedBadge,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
        {
            dataField: "#",
            text: "...",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    return (
        <div>
            <DataTable
                keyField="id"
                columns={columns}
                data={examState.exams ?? []}
                search={true}
                pagination={true}
                button={false}
                dialogButton={true}
                dialogAction={props.setDialog}
                buttonLabel="Add New"
                className="transaction-table"
            />
        </div>
    );
};

const Exams = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [showDialog, setDialog] = useState(false);
    const [showEditDialog, setEditDialog] = useState(false);
    const [state, setState] = useState({});

    const stateObject = useSelector((state) => state.exams);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    useEffect(() => {
        dispatch(getAll());
        dispatch(getAllCategories());
    }, [dispatch]);

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <Breadcrumb.Item active>Exam</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <NewExam
                                setAlertStatus={setAlertStatus}
                                showDialog={showDialog}
                                setDialog={setDialog}
                            />

                            <EditExam
                                setAlertStatus={setAlertStatus}
                                showDialog={showEditDialog}
                                setDialog={setEditDialog}
                            />
                            <ListExams setDialog={setDialog} setEditDialog={setEditDialog} />
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default Exams;
