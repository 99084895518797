import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    Col,
    Row,
    Button,
    Breadcrumb,
    ButtonGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Utils from "../../Util/util";
import DataTable from "../../Components/DataTable";
import {
    getPublishedExams,
    getUnPublishedExams,
    getOne,
} from "../../Store/Actions/Admin/Exams";
import RequestStatus from "../../Components/RequestStatus";
import EditExam from "../Admin/Exams/EditExam";

const ListExams = (props) => {
    const dispatch = useDispatch();

    const getExam = (id) => {
        dispatch(getOne({ id }));
    };

    const actionButtons = (cell, row) => {
        return (
            <>
                <ButtonGroup aria-label="Actions" size="sm" vertical>
                    <Button
                        variant="primary"
                        onClick={() => {
                            getExam(row.id);
                            props.setEditDialog(true);
                        }}
                    >
                        <i className="fa fa-edit" aria-hidden="true" /> Edit
                    </Button>
                    <Button
                        variant="success"
                        as={Link}
                        to={`/exams/${row.id}/sums`}
                    >
                        <i className="fa fa-puzzle-piece" aria-hidden="true" />{" "}
                        Sums
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    const columns = [
        {
            dataField: "name",
            text: "Name",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
        {
            dataField: "#",
            text: "...",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    return (
        <div className="text-center">
            {props.exams && props.exams.length ? (
                <DataTable
                    keyField="id"
                    columns={columns}
                    data={props.exams ?? []}
                    search={false}
                    pagination={false}
                    button={false}
                    dialogButton={false}
                    dialogAction={() => {}}
                    buttonLabel=""
                    className="transaction-table"
                />
            ) : (
                "Data not available."
            )}
        </div>
    );
};

export default function Dashboard() {
    const [alertStatus, setAlertStatus] = useState(true);
    const [showEditDialog, setEditDialog] = useState(false);
    const stateObject = useSelector((state) => state.exams);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPublishedExams({ isPublished: 1 }));
        dispatch(getUnPublishedExams({ isPublished: 0 }));
    }, [dispatch]);

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
            </Breadcrumb>
            <EditExam
                setAlertStatus={setAlertStatus}
                showDialog={showEditDialog}
                setDialog={setEditDialog}
            />
            <Row>
                <Col md="6">
                    <Card>
                        <Card.Header>Published Exams</Card.Header>
                        <Card.Body>
                            <ListExams
                                exams={stateObject.publishedExams}
                                setEditDialog={setEditDialog}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="6">
                    <Card>
                        <Card.Header>Un-Published Exams</Card.Header>
                        <Card.Body>
                            <ListExams
                                exams={stateObject.unPublishedExams}
                                setEditDialog={setEditDialog}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <div className="text-center mt-5">
                <RequestStatus
                    stateObj={stateObject}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
}
