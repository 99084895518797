import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Breadcrumb, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import RequestStatus from "../../Components/RequestStatus";
import { SelectInput } from "../../Components/Input";
import {
    getAll as getPackages,
    getPackagesAssignedToSchool,
    assignPackagesToSchool,
} from "../../Store/Actions/Admin/Packages";
import { getAll as getSchools } from "../../Store/Actions/Admin/Schools";
import { getAll as getAllCategories } from "../../Store/Actions/Admin/Category";
import { searchPackages } from "../../Store/Actions/Admin/Packages";

import { MultiSelect } from "react-multi-select-component";
import { getOptions } from "../../Util/util";
import PackageCard from "../../Components/PackageCard";

const AssignmentForm = ({
    packages,
    assignedPackages,
    schools,
    errors,
    getPackagesAssigned,
    categories,
    filterPackagesByCategory,
    handleFormSubmit,
}) => {
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [schoolId, setSchool] = useState(0);
    const [categoryId, setCategory] = useState(0);

    const getPackageOptions = () => {
        const unassignedPackages = packages.filter((pkg) => {
            return !assignedPackages.some((assigned) => assigned.id === pkg.id);
        });

        const options = unassignedPackages.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        return options;
    };

    const resetForm = (e) => {
        e.preventDefault();
        setSelectedPackages([]);
        setSchool(0);
        setCategory(0);
    };

    const formSubmit = (e) => {
        e.preventDefault();
        const packageIds = selectedPackages.map((item) => item.value);

        const matchingPackages = packages.filter((pkg) =>
            packageIds.includes(pkg.id)
        );

        const filteredPackages = matchingPackages.map((pkg) => ({
            id: pkg.id,
            amount: pkg.amount,
        }));

        const data = {
            schoolId,
            packages: filteredPackages,
        };

        handleFormSubmit(data);
    };

    return (
        <>
            <Form onSubmit={formSubmit}>
                <Row>
                    <Col md={4}>
                        <SelectInput
                            errMessage={errors["schoolId"]}
                            name="schoolId"
                            placeholder="School"
                            label="School"
                            type="text"
                            Options={getOptions(schools, "name", "id")}
                            value={schoolId}
                            handleChange={(e) => {
                                setSchool(e.target.value);
                                getPackagesAssigned(e.target.value);
                            }}
                            controlId="School"
                        />
                    </Col>

                    <Col md={4}>
                        <SelectInput
                            errMessage={errors["categoryId"]}
                            name="categoryId"
                            placeholder="Filter"
                            label="Filter Packages By Category"
                            type="text"
                            Options={getOptions(categories, "name", "id")}
                            value={categoryId}
                            handleChange={(e) => {
                                setCategory(e.target.value);
                                filterPackagesByCategory(e.target.value);
                            }}
                            controlId="Category"
                        />
                    </Col>

                    <Col md={4}>
                        <label>Select Packages</label>
                        <MultiSelect
                            className="mt-2"
                            options={getPackageOptions(packages, "name", "id")}
                            value={selectedPackages}
                            onChange={(items) => {
                                setSelectedPackages(items);
                            }}
                            labelledBy={"Select"}
                            isCreatable={true}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md="12" className="text-right">
                        <Button variant="primary" type="submit">
                            Assign Packages
                        </Button>{" "}
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={resetForm}
                        >
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const AssignPackages = () => {
    const [alertStatus, setAlertStatus] = useState(true);

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.packages);
    const packages = stateObject.packages;
    const assignedPackages = stateObject.assingedPackages;

    const schools = useSelector((state) => state.schools.schools);
    const categories = useSelector((state) => state.categories.categories);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const getPackagesAssigned = (schoolId) => {
        dispatch(getPackagesAssignedToSchool({ schoolId: schoolId }));
    };

    const filterPackagesByCategory = (categoryId) => {
        dispatch(searchPackages({ categoryId, pagination: false }));
    };

    const handleFormSubmit = (data) => {
        console.log(data);
        dispatch(assignPackagesToSchool(data));
    };

    useEffect(() => {
        dispatch(getSchools());
        dispatch(getAllCategories());
        dispatch(getPackages());
    }, []);

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <Breadcrumb.Item active>Assign Packages</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <AssignmentForm
                                packages={packages ?? []}
                                assignedPackages={assignedPackages ?? []}
                                categories={categories ?? []}
                                schools={schools ?? []}
                                errors={errors}
                                getPackagesAssigned={getPackagesAssigned}
                                filterPackagesByCategory={
                                    filterPackagesByCategory
                                }
                                handleFormSubmit={handleFormSubmit}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {assignedPackages.length > 0 ? (
                <>
                    <br />
                    <h5>Assigned Packages</h5>
                    <PackageCard packages={assignedPackages} purchased={true} />
                </>
            ) : (
                ""
            )}
            <div className="text-center mt-5">
                <RequestStatus
                    stateObj={stateObject}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
};

export default AssignPackages;
