import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Breadcrumb, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAuthenticatedUser } from "../Store/Actions/Auth";
import { Input, TextArea, SelectInput } from "../Components/Input";
import RequestStatus from "../Components/RequestStatus";
import {
    getOne as getSchoolProfile,
    updateProfile as updateSchool,
} from "../Store/Actions/Admin/Schools";

import {
    getOne as getStudent,
    updateProfile as updateStudent,
} from "../Store/Actions/Student";
import { getAll as getAllCategories } from "../Store/Actions/Admin/Category";
import { getOptions } from "../Util/util";

const AdminProfile = () => {
    return <></>;
};

const StudentProfile = ({ user }) => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [state, setState] = useState({
        name: "",
        address: "",
        state: "",
        city: "",
        pinCode: "",
        email: "",
        guardianName: "",
        guardianRelation: "",
        phoneNo: "",
        categoryId: "",
        userName: "",
        password: "",
        status: 2,
        updatedAt: new Date(),
    });

    const stateObject = useSelector((state) => state.students);
    const student = stateObject.student;
    const categoryState = useSelector((state) => state.categories);

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };
    useEffect(() => {
        dispatch(getAllCategories());
    }, [dispatch]);

    useEffect(() => {
        if (!student) {
            dispatch(getStudent({ id: user.entityId }));
        }
    }, [student]);

    useEffect(() => {
        initState();
    }, [student]);

    const initState = () => {
        console.log(student);
        if (student) {
            setState({
                name: student.name,
                address: student.address,
                state: student.address,
                city: student.city,
                pinCode: student.pinCode,
                email: student.email,
                guardianName: student.guardianName,
                guardianRelation: student.guardianRelation,
                phoneNo: student.phoneNo,
                categoryId: student.categoryId,
                status: student.status,
                userName: student.loginInfo.loginName,
                updatedAt: new Date(),
            });
        }
    };

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        const data = { ...state, id: student.id };
        dispatch(updateStudent(data));
    };

    const resetForm = (e) => {
        e.preventDefault();
        initState();
    };
    return (
        <>
            <Form onSubmit={submitForm}>
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="name"
                            label="Name"
                            placeholder="Name"
                            value={state.name}
                            onChange={(e) =>
                                onChangeHandler("name", e.target.value)
                            }
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        <TextArea
                            type="text"
                            name="address"
                            label="Address"
                            placeholder="Address"
                            value={state.address}
                            onChange={(e) =>
                                onChangeHandler("address", e.target.value)
                            }
                        />
                    </Col>
                </Row>
                <br />

                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="state"
                            label="State"
                            placeholder="State"
                            value={state.state}
                            onChange={(e) =>
                                onChangeHandler("state", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="city"
                            label="City"
                            placeholder="City"
                            value={state.city}
                            onChange={(e) =>
                                onChangeHandler("city", e.target.value)
                            }
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="number"
                            name="pinCode"
                            label="Pin Code"
                            placeholder="Pin Code"
                            value={state.pinCode}
                            onChange={(e) =>
                                onChangeHandler("pinCode", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="guardianName"
                            label="Guardian Name"
                            placeholder="Guardian Name"
                            value={state.guardianName}
                            onChange={(e) =>
                                onChangeHandler("guardianName", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="guardianRelation"
                            label="Guardian Relation"
                            placeholder="Guardian Relation"
                            value={state.guardianRelation}
                            onChange={(e) =>
                                onChangeHandler(
                                    "guardianRelation",
                                    e.target.value
                                )
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="phoneNo"
                            label="Phone Number"
                            placeholder="Phone Number"
                            value={state.phoneNo}
                            onChange={(e) =>
                                onChangeHandler("phoneNo", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            value={state.email}
                            onChange={(e) =>
                                onChangeHandler("email", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={6}>
                        <SelectInput
                            errMessage={errors["categoryId"]}
                            name="categoryId"
                            placeholder="Category"
                            label="Category"
                            type="text"
                            Options={getOptions(
                                categoryState.categories,
                                "name",
                                "id"
                            )}
                            value={state.categoryId}
                            handleChange={(e) =>
                                onChangeHandler("categoryId", e.target.value)
                            }
                            controlId="Category"
                        />
                    </Col>
                    <Col md={6}>
                        <SelectInput
                            errMessage={errors["status"]}
                            name="status"
                            placeholder="Status"
                            label="Status"
                            type="text"
                            Options={getOptions(
                                [
                                    { name: "Active", id: 1 },
                                    { name: "In-Active", id: 2 },
                                ],
                                "name",
                                "id"
                            )}
                            value={state.status}
                            handleChange={(e) =>
                                onChangeHandler("status", e.target.value)
                            }
                            controlId="Status"
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="username"
                            label="Username"
                            placeholder="Username"
                            value={state.userName}
                            onChange={(e) =>
                                onChangeHandler("userName", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="password"
                            name="password"
                            label="Password"
                            placeholder="Password"
                            value={state.password}
                            onChange={(e) =>
                                onChangeHandler("password", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md="12" className="mt-2 text-middle">
                        <Button variant="primary" type="Submit">
                            Update
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            variant="secondary"
                            onClick={resetForm}
                            type="Button"
                        >
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="text-center mt-5">
                <RequestStatus
                    stateObj={stateObject}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
};

const SchoolProfile = ({ user }) => {
    const [alertStatus, setAlertStatus] = useState(true);
    const schoolState = useSelector((state) => state.schools);
    const school = schoolState.school;

    const [state, setState] = useState({
        name: "",
        address: "",
        state: "",
        city: "",
        pinCode: "",
        contactPerson: "",
        email: "",
        phoneNo: "",
        userName: "",
        password: "",
        createdDate: new Date(),
    });

    const dispatch = useDispatch();

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const setFormInitState = () => {
        if (school) {
            setState({
                id: school.id,
                name: school.name,
                address: school.address,
                state: school.state,
                city: school.city,
                pinCode: school.pinCode,
                contactPerson: school.contactPerson,
                email: school.email,
                phoneNo: school.phoneNo,
                userName: school.loginName,
                password: "",
                status: school.status ?? 0,
                updatedAt: new Date(),
            });
        }
    };

    useEffect(() => {
        setFormInitState();
    }, [school]);

    useEffect(() => {
        dispatch(getSchoolProfile({ id: user.entityId }));
    }, []);

    const resetForm = (e) => {};

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        const data = { ...state };
        dispatch(updateSchool(data));
    };

    return (
        <>
            <Form onSubmit={submitForm}>
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="name"
                            label="Name"
                            placeholder="Name"
                            value={state.name}
                            onChange={(e) =>
                                onChangeHandler("name", e.target.value)
                            }
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        <TextArea
                            type="text"
                            name="address"
                            label="Address"
                            placeholder="Address"
                            value={state.address}
                            onChange={(e) =>
                                onChangeHandler("address", e.target.value)
                            }
                        />
                    </Col>
                </Row>
                <br />

                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="state"
                            label="State"
                            placeholder="State"
                            value={state.state}
                            onChange={(e) =>
                                onChangeHandler("state", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="city"
                            label="City"
                            placeholder="City"
                            value={state.city}
                            onChange={(e) =>
                                onChangeHandler("city", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="number"
                            name="pinCode"
                            label="Pin Code"
                            placeholder="Pin Code"
                            value={state.pinCode}
                            onChange={(e) =>
                                onChangeHandler("pinCode", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="contactPerson"
                            label="Contact Person"
                            placeholder="Contact Person"
                            value={state.contactPerson}
                            onChange={(e) =>
                                onChangeHandler("contactPerson", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="phoneNo"
                            label="Phone Number"
                            placeholder="Phone Number"
                            value={state.phoneNo}
                            onChange={(e) =>
                                onChangeHandler("phoneNo", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            value={state.email}
                            onChange={(e) =>
                                onChangeHandler("email", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="text"
                            name="username"
                            label="Username"
                            placeholder="Username"
                            value={state.userName}
                            onChange={(e) =>
                                onChangeHandler("userName", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md={12}>
                        <Input
                            type="password"
                            name="password"
                            label="Password"
                            placeholder="Password"
                            value={state.password}
                            onChange={(e) =>
                                onChangeHandler("password", e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col md="12" className="mt-2 text-middle">
                        <Button variant="primary" type="Submit">
                            Update
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            variant="secondary"
                            onClick={resetForm}
                            type="Button"
                        >
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="text-center mt-5">
                <RequestStatus
                    stateObj={schoolState}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
};

const Profile = () => {
    const user = getAuthenticatedUser();
    const getUserForm = () => {
        if (user.role === "student") {
            return <StudentProfile user={user} />;
        } else if (user.role === "school-admin") {
            return <SchoolProfile user={user} />;
        } else {
            return <AdminProfile user={user} />;
        }
    };
    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>

                <Breadcrumb.Item active>Profile</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>{getUserForm()}</Card.Body>
            </Card>
        </>
    );
};

export default Profile;
