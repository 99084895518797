import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Offcanvas, Form } from "react-bootstrap";
import { CheckBoxInput, Input, TextArea, SelectInput } from "../../../Components/Input";
import { MultiSelect } from "react-multi-select-component";

import { getExamsByQueryParams } from "../../../Store/Actions/Admin/Exams";
import { create } from "../../../Store/Actions/Admin/Packages";
import { getOptions } from "../../../Util/util";

const NewPackage = (props) => {
    const [selectedExams, setSelectedExams] = useState([]);
    const [options, setOptions] = useState([]);
    const [state, setState] = useState({
        name: "",
        categoryId: "",
        description: "",
        isPublished: false,
        subscriptionCharges: 0,
        createdDate: new Date(),
    });

    const stateObject = useSelector((state) => state.exams);
    const categoryState = useSelector((state) => state.categories);

    const dispatch = useDispatch();

    const getExamsForCategory = (categoryId) => {
        dispatch(getExamsByQueryParams({ queryString: "isPublished=1&categoryId=" + categoryId }));
    };

    useEffect(() => {
        const options = stateObject.exams.map((item) => ({
            label: item.name,
            value: item.id,
            charges: item.subscriptionCharges,
        }));
        setOptions(options);
    }, [stateObject.exams]);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const examIds = selectedExams.map((item) => item.value);
        const data = { ...state, examIds };
        dispatch(create(data));
    };

    const resetForm = (e) => {
        e.preventDefault();
        setState({
            name: "",
            categoryId: "",
            description: "",
            isPublished: false,
            status: 1,
            exams: [],
            subscriptionCharges: 0,
            createdDate: new Date(),
        });
    };

    return (
        <Offcanvas
            placement="end"
            backdrop="true"
            show={props.showDialog}
            onHide={props.setDialog}
            {...props}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create New Package</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Name"
                                value={state.name}
                                onChange={(e) => onChangeHandler("name", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <TextArea
                                type="text"
                                name="description"
                                label="Description"
                                placeholder="Description"
                                value={state.description}
                                onChange={(e) => onChangeHandler("description", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <SelectInput
                                errMessage={errors["categoryId"]}
                                name="categoryId"
                                placeholder="Category"
                                label="Category"
                                type="text"
                                Options={getOptions(categoryState.categories, "name", "id")}
                                value={state.categoryId}
                                handleChange={(e) => {
                                    onChangeHandler("categoryId", e.target.value);
                                    getExamsForCategory(e.target.value);
                                }}
                                controlId="Category"
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <label>Select Exams</label>
                            <MultiSelect
                                options={options}
                                value={selectedExams}
                                onChange={(items) => {
                                    setSelectedExams(items);
                                    let totalCharges = items.reduce((x, item) => {
                                        return x + item.charges;
                                    }, 0);
                                    onChangeHandler("subscriptionCharges", totalCharges);
                                }}
                                labelledBy={"Select"}
                                isCreatable={true}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12} sm="12">
                            <Input
                                type="number"
                                name="charges"
                                label="Charges"
                                placeholder="Charges"
                                value={state.subscriptionCharges}
                                onChange={(e) =>
                                    onChangeHandler("subscriptionCharges", e.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <span style={{ display: "inline-block", float: "left", margin: "5px" }}>
                                <CheckBoxInput
                                    value={state.isPremium}
                                    label="Publish"
                                    onClick={(e) =>
                                        onChangeHandler("isPublished", e.target.checked)
                                    }
                                />
                            </span>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md="12" className="mt-2 text-middle">
                            <Button variant="primary" type="Submit">
                                Create
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={resetForm} type="Button">
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default NewPackage;
