import React from "react";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import { Link } from "react-router-dom";

export const Forbidden = (props) => {
    return (
        <Container>
            <br />
            <br />
            <Row className="justify-content-md-center ">
                <Col md="12" className="text-center">
                    <br />
                    <h1>Forbidden!</h1>
                    <h4>Code 403</h4>

                    <Link to="/">Go Back</Link>
                </Col>
            </Row>
        </Container>
    );
};
