export const AuthTypes = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",

    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

    IS_AUTHENTICATED: "IS_AUTHENTICATED",
    TOKEN_REFRESH_REQUEST: "TOKEN_REFRESH_REQUEST",
    TOKEN_REFRESH_SUCCESS: "TOKEN_REFRESH_SUCCESS",
};

export const TOKEN_EXPIRED = 402;
