import { takeLatest } from "redux-saga/effects";
import BaseSaga from "../Base";
import { ClassTypes } from "../../Actions/Types/Classes";
import { failed } from "../../Actions/Admin/Classes";

const endPoint = "/admin/classes";

const sagaWatchers = BaseSaga(endPoint, failed);
const SagaActions = {
    ...sagaWatchers,
};

export function* watchClassRequests() {
    yield takeLatest(ClassTypes.CREATE, SagaActions.create);
    yield takeLatest(ClassTypes.UPDATE, SagaActions.update);
    yield takeLatest(ClassTypes.DELETE, SagaActions.remove);
    yield takeLatest(ClassTypes.GET_ALL, SagaActions.getAll);
    yield takeLatest(ClassTypes.GET, SagaActions.getOne);
}
