import React from "react";
import moment from "moment";
import { Card, Col, Row, Table } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";

export default function ExamResult({ examAttempt, exam, examResult }) {
    const getDuration = (startTime, endTime) => {
        const startDT = new Date(startTime);
        const endDT = new Date(endTime);
        return Math.ceil((endDT - startDT) / 1000 / 60);
    };

    const renderExamResult = () => {
        return examResult ? (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className="text-center">Sl.No</th>
                        <th className="text-center">Questoion</th>
                        <th className="text-center">Right Answer</th>
                        <th className="text-center">User Answer</th>
                        <th className="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {examResult.map(
                        (
                            item,
                            idx // Return the JSX from the map callback
                        ) => (
                            <tr key={idx}>
                                <td className="text-center">{idx + 1}</td>
                                <td className="text-center">
                                    {item.sum?.question ?? "No question"}
                                </td>
                                {/* Safely access nested property */}
                                <td className="text-center">
                                    {item.correctAnswer}
                                </td>
                                <td className="text-center">
                                    {item.selectedAnswer}
                                </td>
                                <td className="text-center">
                                    {item.isCorrect === 1 ? (
                                        <Badge
                                            bg="success"
                                            className="text-white"
                                        >
                                            Correct
                                        </Badge>
                                    ) : (
                                        <Badge
                                            bg="danger"
                                            className="text-white"
                                        >
                                            Incorrect
                                        </Badge>
                                    )}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </Table>
        ) : (
            <></>
        );
    };
    const renderExamSummry = () => {
        return (
            <Card>
                <Card.Header>Result</Card.Header>
                <Card.Body>
                    <Card.Title>Summary</Card.Title>
                    <Row className="mt-3">
                        <Col md={6}>
                            <Row>
                                <Col md={6}>Start Time</Col>
                                <Col md={6}>
                                    {moment(examAttempt.startTime).format(
                                        "YYYY-MM-DD h:mm:ss "
                                    )}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={6}>End Time</Col>
                                <Col md={6}>
                                    {moment(examAttempt.endTime).format(
                                        "YYYY-MM-DD h:mm:ss "
                                    )}
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={6}>Duration</Col>
                                <Col md={6}>
                                    {getDuration(
                                        examAttempt.startTime,
                                        examAttempt.endTime
                                    )}
                                    &nbsp;Min
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={6}>Total Score : </Col>
                                <Col md={6} className="text-left">
                                    {examAttempt.totalScore} %
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={6}>Total Sums : </Col>
                                <Col md={6} className="text-left">
                                    {examAttempt.totalSums}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={6}>Total Attempted Sums : </Col>
                                <Col md={6} className="text-left">
                                    {examAttempt.totalAttemptedQtn}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={6}>Total Right Answers : </Col>
                                <Col md={6} className="text-left">
                                    {examAttempt.rightAnswers}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={6}>Wrong Answers : </Col>
                                <Col md={6} className="text-left">
                                    {examAttempt.wrongAnswers}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <br />
                    <br />
                    <Card.Title>Details</Card.Title>
                    {renderExamResult()}
                </Card.Body>
            </Card>
        );
    };
    return examAttempt ? renderExamSummry() : "";
}
