import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ButtonLink } from "./Input";
import { Button } from "react-bootstrap";
const { SearchBar } = Search;

const DataTable = (props) => {
    const rowPerPage = 15;
    const [currentPage, setCurrentPage] = useState(1);

    const paginationOption = {
        sizePerPage: rowPerPage,
        onPageChange: (page) => {
            setCurrentPage(page);
        },
        page: currentPage,
    };

    const columns = [
        {
            dataField: "#" + (1 + Math.random() * 99),
            text: "#",
            headerStyle: { width: "40px" },
            style: { width: "40px" },
            formatter: (cell, row, rowIndex) => {
                let rowNumber = (currentPage - 1) * rowPerPage + (rowIndex + 1);
                return <span>{rowNumber}</span>;
            },
        },
        ...props.columns,
    ];
    return (
        <ToolkitProvider
            keyField={props.keyField}
            data={props.data}
            columns={columns}
            search
        >
            {(toolkitprops) => (
                <div
                    className={props.className ? props.className : "data-table"}
                >
                    {props.search ? (
                        <SearchBar {...toolkitprops.searchProps} />
                    ) : (
                        ""
                    )}

                    {props.button ? (
                        <div className="pull-right">
                            <ButtonLink
                                variant="success"
                                to={props.buttonURI}
                                type="button"
                                label={props.buttonTitle}
                                icon="fa fa-plus"
                                size="lg"
                                onClick={() => {}}
                            />
                        </div>
                    ) : (
                        ""
                    )}

                    {props.dialogButton ? (
                        <div className="pull-right">
                            <Button
                                variant="primary"
                                size="md"
                                type="button"
                                onClick={() => props.dialogAction(true)}
                            >
                                {props.buttonLabel}
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}

                    {props.actionButtons ? (
                        <div className="pull-right mr-5 ml-5">
                            {props.actionButtons()}
                        </div>
                    ) : null}

                    {props.displayText ? (
                        <div className="pull-right">
                            <span className="font-weight-bold">
                                {props.displayText}
                            </span>
                        </div>
                    ) : (
                        ""
                    )}

                    {props.boxView ? (
                        <div className="pull-right ml-5px mr-5px">
                            {props.boxView()}
                        </div>
                    ) : (
                        ""
                    )}

                    {props.pagination ? (
                        <>
                            <br />
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                pagination={paginationFactory(paginationOption)}
                            />
                        </>
                    ) : (
                        <BootstrapTable {...toolkitprops.baseProps} />
                    )}
                </div>
            )}
        </ToolkitProvider>
    );
};
export default DataTable;
