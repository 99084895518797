import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Offcanvas, Form } from "react-bootstrap";
import { Input, TextArea } from "../../../Components/Input";
import { create } from "../../../Store/Actions/Admin/Category";

const NewCategory = (props) => {
    const [state, setState] = useState({
        name: "",
        description: "",
        fromAge: "",
        toAge: "",
        createdDate: new Date(),
    });

    const stateObject = useSelector((state) => state.categories);

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const data = { ...state };
        dispatch(create(data));
    };

    const resetForm = (e) => {
        e.preventDefault();
        setState({
            name: "",
            description: "",
            fromAge: "",
            toAge: "",
            createdDate: new Date(),
        });
    };

    return (
        <Offcanvas
            placement="end"
            backdrop="true"
            show={props.showDialog}
            onHide={props.setDialog}
            {...props}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create New Category</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Name"
                                value={state.name}
                                onChange={(e) => onChangeHandler("name", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <TextArea
                                type="text"
                                name="description"
                                label="Description"
                                placeholder="Description"
                                value={state.description}
                                onChange={(e) => onChangeHandler("description", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6}>
                            <Input
                                type="number"
                                name="fromAge"
                                label="From Age"
                                placeholder="From Age"
                                value={state.fromAge}
                                onChange={(e) => onChangeHandler("fromAge", e.target.value)}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                type="number"
                                name="toAge"
                                label="To Age"
                                placeholder="To Age"
                                value={state.toAge}
                                onChange={(e) => onChangeHandler("toAge", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md="12" className="mt-2 text-middle">
                            <Button variant="primary" type="Submit">
                                Create
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={resetForm} type="Button">
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default NewCategory;
