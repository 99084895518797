import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Offcanvas, Form } from "react-bootstrap";
import {
    CheckBoxInput,
    Input,
    TextArea,
    SelectInput,
    DatePickerInput,
} from "../../../Components/Input";
import { update } from "../../../Store/Actions/Admin/Exams";
import { getOptions } from "../../../Util/util";

const EditExam = (props) => {
    const [state, setState] = useState({
        id: "",
        name: "",
        categoryId: "",
        isPremium: 0,
        isPublished: 0,
        totalTime: 0,
        subscriptionCharges: 0,
        examType: "",
        description: "",
        competetionStartDate: null,
        competetionEndDate: null,
        competitionStartTs: null,
        competitionEndTs: null,
        status: 0,
    });

    const stateObject = useSelector((state) => state.exams);
    const categoryState = useSelector((state) => state.categories);
    const exam = stateObject.exam;

    const setFormInitState = () => {
        if (exam) {
            const startDate = exam["competitionStartTs"]
                ? new Date(exam["competitionStartTs"] * 1000)
                : null;

            const endDate = exam["competitionEndTs"]
                ? new Date(exam["competitionEndTs"] * 1000)
                : null;

            console.log(startDate, endDate);
            setState({
                id: exam.id,
                name: exam["name"] ?? "",
                categoryId: exam["categoryId"] ?? "",
                isPremium: exam["isPremium"] ?? 0,
                isPublished: exam["isPublished"] ?? 0,
                totalTime: exam["totalTime"] ?? 0,
                subscriptionCharges: exam["subscriptionCharges"] ?? 0,
                examType: exam["examType"] ?? 0,
                description: exam["description"] ?? "",
                competetionStartDate: startDate,
                competetionEndDate: endDate,
                competitionStartTs: exam["competitionStartTs"] ?? null,
                competitionEndTs: exam["competitionEndTs"] ?? null,
                status: exam["status"] ?? 0,
            });
        }
    };

    useEffect(() => {
        setFormInitState();
    }, [exam]);

    const resetForm = (e) => {
        setFormInitState();
    };

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const convertToTimeStamp = (date) => {
        if (date instanceof Date && !isNaN(date.getTime())) {
            return Math.floor(date.getTime() / 1000);
        } else {
            console.error("Invalid date selected:", date);
            return 0;
        }
    };

    const submitForm = async (e) => {
        e.preventDefault();
        props.setAlertStatus(true);

        const competitionStartTs = convertToTimeStamp(
            state.competetionStartDate
        );

        const competitionEndTs = convertToTimeStamp(state.competetionEndDate);
        const data = {
            ...state,
            competitionStartTs,
            competitionEndTs,
        };
        console.log(data);
        dispatch(update(data));
    };

    const getExamTypes = () => {
        const examTyes = [];
        examTyes[1] = "Main Exam";
        examTyes[2] = "Competition";
        return examTyes;
    };

    const getCompetetionFields = () => {
        if (state.examType === 2) {
            return (
                <>
                    <br />
                    <Row>
                        <Col md={12}>
                            <DatePickerInput
                                errMessage={errors["competitionStartTs"]}
                                icon="fa-calendar"
                                label="Competetion Start Time"
                                dateFormat="yyyy-MM-dd h:mm aa"
                                selected={state.competetionStartDate}
                                onChange={(date) => {
                                    onChangeHandler(
                                        "competetionStartDate",
                                        date
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <DatePickerInput
                                errMessage={errors["competitionEndTs"]}
                                icon="fa-calendar"
                                label="Competetion End Time"
                                dateFormat="yyyy-MM-dd h:mm aa"
                                selected={state.competetionEndDate}
                                onChange={(date) => {
                                    onChangeHandler("competetionEndDate", date);
                                }}
                            />
                        </Col>
                    </Row>
                </>
            );
        } else {
            return "";
        }
    };

    return (
        <Offcanvas
            placement="end"
            backdrop="true"
            show={props.showDialog}
            onHide={props.setDialog}
            {...props}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Exam</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Name"
                                value={state.name}
                                onChange={(e) =>
                                    onChangeHandler("name", e.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <TextArea
                                type="text"
                                name="description"
                                label="Description"
                                placeholder="Description"
                                value={state.description}
                                onChange={(e) =>
                                    onChangeHandler(
                                        "description",
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md={12}>
                            <SelectInput
                                errMessage={errors["examType"]}
                                name="examType"
                                placeholder="Exam Type"
                                label="Exam Type"
                                type="text"
                                Options={getExamTypes()}
                                value={state.examType}
                                handleChange={(e) =>
                                    onChangeHandler("examType", e.target.value)
                                }
                                controlId="Exam Type"
                            />
                        </Col>
                    </Row>
                    {getCompetetionFields()}
                    <br />
                    <Row>
                        <Col md={12}>
                            <SelectInput
                                errMessage={errors["categoryId"]}
                                name="categoryId"
                                placeholder="Category"
                                label="Category"
                                type="text"
                                Options={getOptions(
                                    categoryState.categories,
                                    "name",
                                    "id"
                                )}
                                value={state.categoryId}
                                handleChange={(e) =>
                                    onChangeHandler(
                                        "categoryId",
                                        e.target.value
                                    )
                                }
                                controlId="Category"
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6} sm="6">
                            <span
                                style={{
                                    display: "inline-block",
                                    float: "left",
                                    margin: "5px",
                                }}
                            >
                                <CheckBoxInput
                                    value={state.isPremium}
                                    label="Is Premium"
                                    checked={state.isPremium ?? false}
                                    onClick={(e) =>
                                        onChangeHandler(
                                            "isPremium",
                                            e.target.checked
                                        )
                                    }
                                />
                            </span>
                        </Col>
                        <Col md={6} sm="6">
                            {state.isPremium ? (
                                <Input
                                    type="number"
                                    name="charges"
                                    label="Charges"
                                    placeholder="Charges"
                                    value={state.subscriptionCharges}
                                    onChange={(e) =>
                                        onChangeHandler(
                                            "subscriptionCharges",
                                            e.target.value
                                        )
                                    }
                                />
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="number"
                                name="duration"
                                label="Exam Duration (Min)"
                                placeholder="Exam Duration  (Min)"
                                value={state.totalTime}
                                onChange={(e) =>
                                    onChangeHandler("totalTime", e.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6}>
                            <span
                                style={{
                                    display: "inline-block",
                                    float: "left",
                                    margin: "5px",
                                }}
                            >
                                <CheckBoxInput
                                    value={state.isPublished}
                                    label="Publish"
                                    checked={state.isPublished ?? false}
                                    onClick={(e) =>
                                        onChangeHandler(
                                            "isPublished",
                                            e.target.checked
                                        )
                                    }
                                />
                            </span>
                        </Col>
                        <Col md={6}>
                            <span
                                style={{
                                    display: "inline-block",
                                    float: "left",
                                    margin: "5px",
                                }}
                            >
                                <CheckBoxInput
                                    value={state.status}
                                    label="Activate"
                                    checked={state.status ?? false}
                                    onClick={(e) =>
                                        onChangeHandler(
                                            "status",
                                            e.target.checked
                                        )
                                    }
                                />
                            </span>
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md="12" className="mt-2 text-middle">
                            <Button variant="primary" type="Submit">
                                Update
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant="secondary"
                                onClick={resetForm}
                                type="Button"
                            >
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default EditExam;
