import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { uploadSums } from "../../Store/Actions/Admin/Exams";

function UploadSums(props) {
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) return;

        props.setAlertStatus(true);
        const data = {
            file: file,
            examId: props.examId,
        };
        dispatch(uploadSums(data));
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload Sums
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload CSV File</Form.Label>
                        <Form.Control
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                    </Form.Group>

                    <Button
                        type="submit"
                        variant="primary"
                        className="pull-right"
                    >
                        Upload
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default UploadSums;
