import { takeLatest, put } from "redux-saga/effects";
import BaseSaga from "../Base";
import { SchoolTypes } from "../../Actions/Types/Schools";
import { failed, getAll as getAllSchools } from "../../Actions/Admin/Schools";

const endPoint = "/admin/schools";

const sagaWatchers = BaseSaga(endPoint, failed);
const SagaActions = {
    ...sagaWatchers,
};

const refreshData = function* (action) {
    yield put(getAllSchools());
};

const updateSchoolProfile = function* (action) {
    yield sagaWatchers.update(action, endPoint + "/update-school-profile");
};

export function* watchSchoolRequests() {
    yield takeLatest(SchoolTypes.CREATE, SagaActions.create);
    yield takeLatest(SchoolTypes.UPDATE, SagaActions.update);
    yield takeLatest(SchoolTypes.DELETE, SagaActions.remove);
    yield takeLatest(SchoolTypes.GET_ALL, SagaActions.getAll);
    yield takeLatest(SchoolTypes.GET, SagaActions.getOne);
    yield takeLatest(
        [SchoolTypes.CREATE_SUCCESS, SchoolTypes.UPDATE_SUCCESS],
        refreshData
    );
    yield takeLatest(SchoolTypes.UPDATE_SCHOOL_PROFILE, updateSchoolProfile);
}
