import React from "react";
import { getAuthenticatedUser } from "../../Store/Actions/Auth";
import AdminDashboard from "./AdminDashboard";
import SchoolDashboard from "./SchoolDashboard";
import StudentDashboard from "./StudentDashboard";
import Home from "./Home";

export default function Dashboard() {
    const user = getAuthenticatedUser();
    if (user.role === "admin") {
        return <AdminDashboard />;
    } else if (user.role === "school-admin") {
        return <SchoolDashboard />;
    } else if (user.role === "student") {
        return <StudentDashboard />;
    }
    return <Home />;
}
