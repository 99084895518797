import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Offcanvas, Form } from "react-bootstrap";
import {
    CheckBoxInput,
    Input,
    TextArea,
    SelectInput,
    DatePickerInput,
} from "../../../Components/Input";
import { create } from "../../../Store/Actions/Admin/Exams";
import { getOptions } from "../../../Util/util";

const NewExam = (props) => {
    const [state, setState] = useState({
        name: "",
        categoryId: "",
        isPremium: false,
        isPublished: false,
        totalTime: 0,
        subscriptionCharges: 0,
        examType: "",
        description: "",
        competetionStartDate: null,
        competetionEndDate: null,
        competetionStartTs: null,
        competetionEndTs: null,
        createdDate: new Date(),
    });

    const stateObject = useSelector((state) => state.exams);
    const categoryState = useSelector((state) => state.categories);

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const data = { ...state };
        dispatch(create(data));
    };

    const resetForm = (e) => {
        e.preventDefault();
        setState({
            name: "",
            categoryId: "",
            isPremium: false,
            isPublished: false,
            totalTime: 0,
            subscriptionCharges: 0,
            examType: "",
            description: "",
            competetionStartDate: null,
            competetionEndDate: null,
            competetionStartTs: null,
            competetionEndTs: null,
            createdDate: new Date(),
        });
    };

    const getExamTypes = () => {
        const examTyes = [];
        examTyes[1] = "Main Exam";
        examTyes[2] = "Competition";
        examTyes[3] = "Practice";
        return examTyes;
    };

    const getCompetitionFields = () => {
        if (state.examType == 2) {
            return (
                <>
                    <br />
                    <Row>
                        <Col md={12}>
                            <DatePickerInput
                                errMessage={errors["competetionStartTs"]}
                                icon="fa-calendar"
                                label="Competetion Start Time"
                                dateFormat="yyyy-MM-dd h:mm aa"
                                selected={state.competetionStartDate}
                                onChange={(date) => {
                                    const timestamp = Math.floor(new Date(date).getTime() / 1000);
                                    onChangeHandler("completionStartTs", timestamp);
                                    onChangeHandler("competetionStartDate", date);
                                }}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <DatePickerInput
                                errMessage={errors["competetionEndTs"]}
                                icon="fa-calendar"
                                label="Competetion End Time"
                                dateFormat="yyyy-MM-dd h:mm aa"
                                selected={state.competetionEndDate}
                                onChange={(date) => {
                                    const timestamp = Math.floor(new Date(date).getTime() / 1000);
                                    onChangeHandler("completionEndTs", timestamp);
                                    onChangeHandler("competetionEndDate", date);
                                }}
                            />
                        </Col>
                    </Row>
                </>
            );
        } else {
            return "";
        }
    };

    return (
        <Offcanvas
            placement="end"
            backdrop="true"
            show={props.showDialog}
            onHide={props.setDialog}
            {...props}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create New Exam</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={12}>
                            <Input
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Name"
                                value={state.name}
                                onChange={(e) => onChangeHandler("name", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <TextArea
                                type="text"
                                name="description"
                                label="Description"
                                placeholder="Description"
                                value={state.description}
                                onChange={(e) => onChangeHandler("description", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md={12}>
                            <SelectInput
                                errMessage={errors["examType"]}
                                name="examType"
                                placeholder="Exam Type"
                                label="Exam Type"
                                type="text"
                                Options={getExamTypes()}
                                value={state.examType}
                                handleChange={(e) => onChangeHandler("examType", e.target.value)}
                                controlId="Exam Type"
                            />
                        </Col>
                    </Row>
                    {getCompetitionFields()}
                    <br />
                    <Row>
                        <Col md={12}>
                            <SelectInput
                                errMessage={errors["categoryId"]}
                                name="categoryId"
                                placeholder="Category"
                                label="Category"
                                type="text"
                                Options={getOptions(categoryState.categories, "name", "id")}
                                value={state.categoryId}
                                handleChange={(e) => onChangeHandler("categoryId", e.target.value)}
                                controlId="Category"
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6} sm="6">
                            <span style={{ display: "inline-block", float: "left", margin: "5px" }}>
                                <CheckBoxInput
                                    value={state.isPremium}
                                    label="Is Premium"
                                    onClick={(e) => onChangeHandler("isPremium", e.target.checked)}
                                />
                            </span>
                        </Col>
                        <Col md={6} sm="6">
                            {state.isPremium ? (
                                <Input
                                    type="number"
                                    name="charges"
                                    label="Charges"
                                    placeholder="Charges"
                                    value={state.subscriptionCharges}
                                    onChange={(e) =>
                                        onChangeHandler("subscriptionCharges", e.target.value)
                                    }
                                />
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <Input
                                type="number"
                                name="duration"
                                label="Exam Duration (Min)"
                                placeholder="Exam Duration  (Min)"
                                value={state.totalTime}
                                onChange={(e) => onChangeHandler("totalTime", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={12}>
                            <span style={{ display: "inline-block", float: "left", margin: "5px" }}>
                                <CheckBoxInput
                                    value={state.isPremium}
                                    label="Publish"
                                    onClick={(e) =>
                                        onChangeHandler("isPublished", e.target.checked)
                                    }
                                />
                            </span>
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col md="12" className="mt-2 text-middle">
                            <Button variant="primary" type="Submit">
                                Create
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={resetForm} type="Button">
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default NewExam;
