import { getTypes } from "./TypeGenerator";

export const PackageTypes = {
    ...getTypes("PACKAGES"),

    GET_PUBLISHED_PACKAGES: "GET_PUBLISHED_PACKAGES",
    GET_PUBLISHED_PACKAGES_SUCCESS: "GET_PUBLISHED_PACKAGES_SUCCESS",

    GET_UNPUBLISHED_PACKAGES: "GET_UNPUBLISHED_PACKAGES",
    GET_UNPUBLISHED_PACKAGES_SUCCESS: "GET_UNPUBLISHED_PACKAGES_SUCCESS",

    SEARCH_PACKAGES: "SEARCH_PACKAGES",
    SEARCH_PACKAGES_SUCCESS: "SEARCH_PACKAGES_SUCCESS",

    PURCHASE_PACKAGES: "PURCHASE_PACKAGES",
    PURCHASE_PACKAGES_SUCCESS: "PURCHASE_PACKAGES_SUCCESS",

    GET_USER_PACKAGES: "GET_USER_PACKAGES",
    GET_USER_PACKAGES_SUCCESS: "GET_USER_PACKAGES_SUCCESS",

    GET_PACKAGES_ASSIGNED_TO_SCHOOL: "GET_PACKAGES_ASSIGNED_TO_SCHOOL",
    GET_PACKAGES_ASSIGNED_TO_SCHOOL_SUCCESS:
        "GET_PACKAGES_ASSIGNED_TO_SCHOOL_SUCCESS",

    ASSIGN_PACKAGES_TO_SCHOOL: "ASSIGN_PACKAGES_TO_SCHOOL",
    ASSIGN_PACKAGES_TO_SCHOOL_SUCCESS: "ASSIGN_PACKAGES_TO_SCHOOL_SUCCESS",
};
