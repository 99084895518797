import { DashboardTypes as ActionConstant } from "../Actions/Types/Dashboard";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    data: null,
};

const DashboardReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.GET_SCHOOL_DASHBOARD:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_SCHOOL_DASHBOARD_SUCCESS:
            state = {
                ...state,
                data: action.payload.data,
            };
            break;

        case ActionConstant.GET_STUDENT_DASHBOARD:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_STUDENT_DASHBOARD_SUCCESS:
            state = {
                ...state,
                data: action.payload.data,
            };
            break;
        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default DashboardReducer;
