import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Card, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { getOne, getExamAttempt } from "../Store/Actions/Admin/Exams";
import RequestStatus from "../Components/RequestStatus";
import ExamResult from "../Components/ExamResult";

const ExamAttemptDetails = () => {
    const { id, examAttemptId } = useParams();
    const [alertStatus, setAlertStatus] = useState(true);
    const dispatch = useDispatch();

    const state = useSelector((state) => state.exams);
    const exam = state.exam;
    const examAttempt = state.examAttempt;
    const examResult = state.examResult;

    useEffect(() => {
        dispatch(getOne({ id }));
        dispatch(getExamAttempt({ examAttemptId }));
    }, []);
    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/attempted-exams">Attempted Exams</Link>
                </li>
                <Breadcrumb.Item active>
                    {exam ? exam.name : ""}
                </Breadcrumb.Item>
            </Breadcrumb>
            <ExamResult
                exam={exam}
                examAttempt={examAttempt}
                examResult={examResult}
            />
            <div className="text-center">
                <RequestStatus
                    stateObj={state}
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
            </div>
        </>
    );
};

export default ExamAttemptDetails;
