import getActions from "../Base";
import { PackageTypes } from "../Types/Packages";

const baseActions = getActions(PackageTypes);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const getPublishedPackages = (data) => {
    return {
        type: PackageTypes["GET_PUBLISHED_PACKAGES"],
        payload: data,
        success: PackageTypes["GET_PUBLISHED_PACKAGES_SUCCESS"],
    };
};

const getUnPublishedPackages = (data) => {
    return {
        type: PackageTypes["GET_UNPUBLISHED_PACKAGES"],
        payload: data,
        success: PackageTypes["GET_UNPUBLISHED_PACKAGES_SUCCESS"],
    };
};

const searchPackages = (data) => {
    return {
        type: PackageTypes["SEARCH_PACKAGES"],
        payload: data,
        success: PackageTypes["SEARCH_PACKAGES_SUCCESS"],
    };
};

const getUserPackages = (data) => {
    return {
        type: PackageTypes["GET_USER_PACKAGES"],
        payload: data,
        success: PackageTypes["GET_USER_PACKAGES_SUCCESS"],
    };
};

const purchasePackage = (data) => {
    return {
        type: PackageTypes["PURCHASE_PACKAGES"],
        payload: data,
        success: PackageTypes["PURCHASE_PACKAGES_SUCCESS"],
    };
};

const assignPackagesToSchool = (data) => {
    return {
        type: PackageTypes["ASSIGN_PACKAGES_TO_SCHOOL"],
        payload: data,
        success: PackageTypes["ASSIGN_PACKAGES_TO_SCHOOL_SUCCESS"],
    };
};

const getPackagesAssignedToSchool = (data) => {
    return {
        type: PackageTypes["GET_PACKAGES_ASSIGNED_TO_SCHOOL"],
        payload: data,
        success: PackageTypes["GET_PACKAGES_ASSIGNED_TO_SCHOOL_SUCCESS"],
    };
};

export {
    create,
    update,
    remove,
    getAll,
    getOne,
    failed,
    getPublishedPackages,
    getUnPublishedPackages,
    searchPackages,
    purchasePackage,
    getUserPackages,
    assignPackagesToSchool,
    getPackagesAssignedToSchool,
};
