import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../App";

import Exams from "../Pages/Admin/Exams/Exams";
import Sums from "../Pages/Admin/Sums";
import Schools from "../Pages/Admin/Schools";
import Students from "../Pages/Schools/Students";
import Packages from "../Pages/Admin/Packages";

import Classes from "../Pages/Classes";
import Subjects from "../Pages/Subjects";
import Category from "../Pages/Admin/Category/Categories";

import AdminRoutes from "./AdminRoutes";
import PrivateRoute from "./PrivateRoutes";
import SignIn from "../Pages/SignIn";
import { Forbidden } from "../Pages/Forbidden";
import Dashboard from "../Pages/Dashboards/index";
import PurchaseExams from "../Pages/PurchaseExams";
import PurchasePackage from "../Pages/PurchasePackages";
import PurchasedExams from "../Pages/PurchasedExams";
import PurchasedPackages from "../Pages/PurchasedPackages";
import ExamAttempts from "../Pages/ExamAttempt";
import ExamAttemptDetails from "../Pages/ExamAttemptDetails";
import ListExamsAttempted from "../Pages/ListAttemptedExams";
import AssignPackages from "../Pages/Admin/AssignPackage";
import AssignExams from "../Pages/Admin/AssignExams";
import Profile from "../Pages/Profile";
import Home from "../Pages/Dashboards/Home";

export default function Router() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/home" element={<Home />} />
                    <Route element={<App />}>
                        <Route
                            index
                            element={<PrivateRoute element={<Dashboard />} />}
                        />

                        <Route
                            path="/category"
                            element={<AdminRoutes element={<Category />} />}
                        />

                        <Route
                            path="/exams"
                            element={<AdminRoutes element={<Exams />} />}
                        />

                        <Route
                            path="/packages"
                            element={<AdminRoutes element={<Packages />} />}
                        />

                        <Route
                            path="/exams/:id/sums"
                            element={<AdminRoutes element={<Sums />} />}
                        />

                        <Route
                            path="/classes"
                            element={<AdminRoutes element={<Classes />} />}
                        />

                        <Route
                            path="/subjects"
                            element={<AdminRoutes element={<Subjects />} />}
                        />

                        <Route
                            path="/schools"
                            element={<AdminRoutes element={<Schools />} />}
                        />

                        <Route
                            path="/assign-packages"
                            element={
                                <AdminRoutes element={<AssignPackages />} />
                            }
                        />

                        <Route
                            path="/assign-exams"
                            element={<AdminRoutes element={<AssignExams />} />}
                        />

                        <Route
                            path="/students"
                            element={<PrivateRoute element={<Students />} />}
                        />

                        <Route
                            path="/purchase-exams"
                            element={
                                <PrivateRoute element={<PurchaseExams />} />
                            }
                        />

                        <Route
                            path="/purchase-packages"
                            element={
                                <PrivateRoute element={<PurchasePackage />} />
                            }
                        />
                        <Route
                            path="/purchased-exams"
                            element={
                                <PrivateRoute element={<PurchasedExams />} />
                            }
                        />

                        <Route
                            path="/purchased-packages"
                            element={
                                <PrivateRoute element={<PurchasedPackages />} />
                            }
                        />

                        <Route
                            path="/attempted-exams"
                            element={
                                <PrivateRoute
                                    element={<ListExamsAttempted />}
                                />
                            }
                        />

                        <Route
                            path="/attempt-exam/:id/:examAttemptId"
                            element={
                                <PrivateRoute element={<ExamAttempts />} />
                            }
                        />

                        <Route
                            path="/exam-attempt-details/:id/:examAttemptId"
                            element={
                                <PrivateRoute
                                    element={<ExamAttemptDetails />}
                                />
                            }
                        />
                        <Route
                            path="/profile"
                            element={<PrivateRoute element={<Profile />} />}
                        />
                    </Route>

                    <Route path="/forbidden" element={<Forbidden />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}
