export const getTypes = (prefix) => {
    const object = {
        GET: `${prefix}_GET`,
        GET_SUCCESS: `${prefix}_GET_SUCCESS`,

        GET_ALL: `${prefix}_GET_ALL`,
        GET_ALL_SUCCESS: `${prefix}_GET_ALL_SUCCESS`,

        UPDATE: `${prefix}_UPDATE`,
        UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,

        DELETE: `${prefix}_DELETE`,
        DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,

        CREATE: `${prefix}_CREATE`,
        CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,

        ERROR: `${prefix}_ERROR`,
    };
    return object;
};
