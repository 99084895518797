import React from "react";
import { Form, Spinner, InputGroup, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";
import DatePicker from "react-datepicker";
import moment from "moment";

export const Input = (props) => {
    if (!props.icon) {
        return (
            <Form.Group controlId={"form" + props.name}>
                {props.label ? <Form.Label>{props.label}</Form.Label> : ""}
                <Form.Control
                    {...props}
                    type={props.type}
                    placeholder={
                        props.placeholder ? props.placeholder : props.label
                    }
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
                <Form.Text className="text-danger">
                    {props.errMessage}
                </Form.Text>
            </Form.Group>
        );
    }
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : ""}

            <InputGroup>
                <InputGroup.Text id="basic-addon1">
                    <i className={props.icon} size="16" />
                </InputGroup.Text>
                <Form.Control
                    {...props}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
            </InputGroup>
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </>
    );
};

export const InputButtonGroup = (props) => {
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : ""}

            <InputGroup>
                <Form.Control
                    {...props}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={props.btnOnClick}
                >
                    {props.btnText}
                </Button>
            </InputGroup>
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </>
    );
};

export const TextArea = (props) => {
    return (
        <Form.Group controlid={"form" + props.name}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                as="textarea"
                rows={props.rows ?? 4}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                name={props.name}
            />
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </Form.Group>
    );
};

export const SearchInput = (props) => {
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : ""}
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fa fa-search"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                    autoFocus={true}
                    ref={props.ref}
                />
            </InputGroup>
        </>
    );
};

export const SearchableSelect = (props) => {
    return (
        <Form.Group controlid={"form" + props.name}>
            <label>{props.label}</label>
            <InputGroup className="mb-3 searchable-select">
                <Select
                    value={props}
                    values={props.Options.filter(
                        (option) => option.value === props.value
                    )}
                    options={props.Options}
                    onChange={props.onChange}
                />
            </InputGroup>

            <div className="text-danger">{props.errMessage}</div>
        </Form.Group>
    );
};
export const SelectInput = (props) => (
    <Form.Group controlid={"form" + props.name}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Select
            name={props.name}
            value={props.value}
            id={props.id}
            onChange={props.handleChange}
            className="select select-control"
        >
            <option value="">
                {props.defaulttext ? props.defaulttext : "Select"}
            </option>
            {Object.entries(props.Options).map(([k, v]) => (
                <option key={k} value={k}>
                    {v}
                </option>
            ))}
        </Form.Select>
        <Form.Text className="text-danger">{props.errMessage}</Form.Text>
    </Form.Group>
);

export const SelectInputGroup = (props) => {
    return (
        <Form.Select
            name={props.name}
            value={props.value}
            id={props.id}
            onChange={props.onChange}
            className="select form-control"
        >
            {Object.entries(props.Options).map(([k, v]) => (
                <option key={k} value={v}>
                    {k}
                </option>
            ))}
        </Form.Select>
    );
};
export const CustomButton = (props) => {
    if (!props.icon) {
        return (
            <Button
                variant={props.variant}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
            >
                {props.label}
            </Button>
        );
    } else {
        return (
            <Button
                variant={props.variant}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
            >
                <i className={props.icon} size="16" /> {props.label}
            </Button>
        );
    }
};

export const ButtonLink = (props) => {
    if (!props.icon) {
        return (
            <Button
                as={Link}
                to={props.to}
                variant={props.variant}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
                size="lg"
            >
                {props.label}
            </Button>
        );
    } else {
        return (
            <Button
                as={Link}
                to={props.to}
                variant={props.variant}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
                size="lg"
            >
                <i className={props.icon} size="16" /> &nbsp;
                {props.label}
            </Button>
        );
    }
};

export const FileInput = (props) => {
    return (
        <Form.Group controlid={"form" + props.name}>
            {props.multiple ? (
                <Form.File
                    name={props.name}
                    label={props.label}
                    onChange={props.onChange}
                    accept={props.accept}
                    id={props.id}
                    className={props.className}
                    ref={props.ref}
                    multiple="multiple"
                />
            ) : (
                <Form.File
                    name={props.name}
                    label={props.label}
                    onChange={props.onChange}
                    accept={props.accept}
                    id={props.id}
                    className={props.className}
                    ref={props.ref}
                />
            )}
        </Form.Group>
    );
};

const renderCheckBoxInputChecked = (props) => {
    return (
        <Form.Check
            type={props.type ? props.type : "checkbox"}
            label={props.label}
            value={props.value}
            onClick={props.onClick}
            name={props.name}
            defaultChecked="true"
        />
    );
};
const renderCheckBoxInputDefault = (props) => {
    return (
        <Form.Check
            type={props.type ? props.type : "checkbox"}
            label={props.label}
            value={props.value}
            onClick={props.onClick}
            name={props.name}
        />
    );
};

export const CheckBoxInput = (props) => {
    return (
        <Form.Group controlid={props.controlId}>
            {props.checked
                ? renderCheckBoxInputChecked(props)
                : renderCheckBoxInputDefault(props)}
        </Form.Group>
    );
};

export const Preloader = (props) => {
    return <Spinner animation="border" />;
};

export const DisplaySelectedImage = (props) => {
    const removeImage = () => {
        props.removeImage(props.index);
    };
    return (
        <div>
            <Button
                className="text-danger photo-delete"
                variant="link"
                onClick={removeImage}
            >
                <i className="fa fa-times" aria-hidden="true"></i>
            </Button>
            <div className="product-image">
                <img src={props.src} alt="Product" width="100px" />
            </div>
        </div>
    );
};

export const DatePickerInput = (props) => {
    return (
        <Form.Group controlid={"form" + props.name}>
            {props.label ? <Form.Label>{props.label}</Form.Label> : ""}
            <br />
            <DatePicker
                locale={moment.locale("en-IN")}
                showIcon
                showTimeSelect
                dateFormat={props.dateFormat}
                selected={props.selected}
                onChange={props.onChange}
                icon="fa fa-calendar"
                className="form-control calendar_icon"
            />
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </Form.Group>
    );
};

export const StatusBadge = (props) => {
    if (props.status === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Active
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                De-Activated
            </Badge>
        );
    }
};
