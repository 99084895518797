import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, Col, Row, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import RequestStatus from "../../../Components/RequestStatus";

import NewPackage from "./NewPackage";
import EditPackage from "./EditPackage";
import ListPackages from "./ListPackages";

const Packages = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [showDialog, setDialog] = useState(false);
    const [showEditDialog, setEditDialog] = useState(false);

    const stateObject = useSelector((state) => state.exams);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <Breadcrumb.Item active>Packages</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <NewPackage
                                setAlertStatus={setAlertStatus}
                                showDialog={showDialog}
                                setDialog={setDialog}
                            />

                            <EditPackage
                                setAlertStatus={setAlertStatus}
                                showDialog={showEditDialog}
                                setDialog={setEditDialog}
                            />
                            <ListPackages setDialog={setDialog} setEditDialog={setEditDialog} />
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default Packages;
