import { takeEvery, call, put } from "redux-saga/effects";
import { AuthTypes } from "../Actions/Types/Auth";
import axiosInstance from "../../Util/axios";
import { loginSuccess, loginError, logoutSuccess, refreshTokenSuccess } from "../Actions/Auth";
import * as REST from "../../Services/rest.service";

import jwt_decode from "jwt-decode";

//Signin functinality
const login = (user) => {
    return async () => {
        const res = await axiosInstance()
            .post("/auth/login", {
                ...user,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return res;
    };
};

function* loginRequest(action) {
    try {
        const response = yield call(login(action.payload));
        if (response.status === 200) {
            const decoded_info = jwt_decode(response.data.access_token);
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("user", JSON.stringify(decoded_info.user));
            localStorage.setItem("refresh_token", response.data.refresh_token);
            response.data.user = {
                ...decoded_info.user,
            };
            yield put(loginSuccess(response.data));
        } else {
            yield put(loginError(response.data));
        }
    } catch (err) {
        console.log(err);
    }
}
function* isUserLoggedIn(action) {
    try {
        const access_token = localStorage.getItem("access_token");
        const refresh_token = localStorage.getItem("refresh_token");
        if (access_token) {
            const user = JSON.parse(localStorage.getItem("user"));

            yield put(
                loginSuccess({
                    access_token,
                    refresh_token,
                    user,
                })
            );
        } else {
            yield put(
                loginError({
                    authenticated: false,
                    message: "",
                })
            );
        }
    } catch (err) {
        console.log(err);
    }
}

function* logoutRequest(action) {
    try {
        const refresh_token = localStorage.getItem("refresh_token");
        let response = yield call(REST.post("/auth/logout", { refresh_token: refresh_token }));
        if (!response) {
            let err = new Error("Request Failed");
            err.status = 500;
            throw err;
        }
        localStorage.clear();
        yield put(logoutSuccess({ message: "" }));
    } catch (err) {
        yield put(logoutSuccess({ message: "" }));
    }
}

const refreshToken = (action) => {
    return async (dispatch) => {
        const refresh_token = localStorage.getItem("refresh_token");
        const res = await axiosInstance()
            .post("/auth/refresh-token", {
                refresh_token,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return res;
    };
};

function* tokenRefreshRequest(action) {
    const res = yield call(refreshToken());
    if (res.status === 200) {
        const decoded_info = jwt_decode(res.data.access_token);
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("user", JSON.stringify(decoded_info.user));
        localStorage.setItem("refresh_token", res.data.refresh_token);
        res.data.user = {
            ...decoded_info.user,
        };
        yield put(loginSuccess(res.data));
        yield put(refreshTokenSuccess());
        yield put({
            type: action.payload.cb_const,
            payload: action.payload.cb_data,
        });
    } else {
        //localStorage.clear();
        yield put(
            loginError({
                authenticated: false,
                message: "Session expired!",
            })
        );
    }
}

export function* watchAuthRequests() {
    yield takeEvery(AuthTypes.LOGIN_REQUEST, loginRequest);
    yield takeEvery(AuthTypes.IS_AUTHENTICATED, isUserLoggedIn);
    yield takeEvery(AuthTypes.LOGOUT_REQUEST, logoutRequest);
    yield takeEvery(AuthTypes.TOKEN_REFRESH_REQUEST, tokenRefreshRequest);
}
