import React from "react";
import { Preloader } from "./Input";

const RequestStatus = (props) => {
    const showPreloader = () => {
        return (
            <div className="text-center">
                <br />
                <Preloader />
            </div>
        );
    };
    const showSuccessAlert = () => {
        setTimeout(() => {
            props.setAlertStatus(false);
        }, 5000);
        return (
            <div className="notification-container top-right">
                <div className="notification success">
                    <button onClick={() => props.setAlertStatus(false)}>
                        <i className="fa fa-close" size="24" />
                    </button>
                    <div className="notification-image">
                        <i className="fa fa-check fa-4" aria-hidden="true" />
                    </div>
                    <div>
                        <p className="notification-message">{props.stateObj.message}</p>
                    </div>
                </div>
            </div>
        );
    };
    const showDangerAlert = () => {
        setTimeout(() => {
            props.setAlertStatus(false);
        }, 5000);
        return (
            <div className="notification-container top-right">
                <div className="notification danger">
                    <button onClick={() => props.setAlertStatus(false)}>
                        <i className="fa fa-close fa-4" aria-hidden="true" />
                    </button>
                    <div className="notification-image">
                        <i className="fa fa-bolt fa-4" aria-hidden="true" />
                    </div>
                    <div>
                        <p className="notification-message">{props.stateObj.message}</p>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div>
            {props.stateObj.PROCESSING ? showPreloader() : ""}
            {props.stateObj.REQUEST_SUCCESS && props.alertStatus && props.stateObj.message
                ? showSuccessAlert()
                : ""}
            {props.stateObj.REQUEST_FAILED && props.stateObj.message && props.alertStatus
                ? showDangerAlert()
                : ""}
        </div>
    );
};
export default RequestStatus;
