import getActions from "./Base";
import { StudentTypes } from "./Types/Students";

const baseActions = getActions(StudentTypes);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const updateProfile = (data) => {
    return {
        type: StudentTypes.UPDATE_STUDENT_PROFILE,
        payload: data,
        success: StudentTypes.UPDATE_STUDENT_PROFILE_SUCCESS,
    };
};

export { create, update, remove, getAll, getOne, failed, updateProfile };
