import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getAuthenticatedUser } from "../Store/Actions/Auth";

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
});

const PrivateRoute = ({ authenticated, role, element }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = getAuthenticatedUser();
        if (!user) {
            navigate("/home");
        }
    }, [navigate]);

    // Redirect if not authenticated
    if (!authenticated) {
        return null; // or any loading indicator or redirect component
    }

    return element;
};

export default connect(mapStateToProps)(PrivateRoute);
