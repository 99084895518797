import { CategoryTypes as ActionConstant } from "../../Actions/Types/Category";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    categoryId: 0,
    categories: [],
    categoryObj: null,
};

const CategoryReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.CREATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_SUCCESS:
            const categories = state.categories;
            categories.push(action.payload.object);
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                categories: categories,
            };
            break;
        case ActionConstant.UPDATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.UPDATE_SUCCESS:
            const updatedCategory = action.payload.object;
            if (updatedCategory) {
                const index = state.categories.findIndex((item) => item.id === updatedCategory.id);
                if (index !== -1) {
                    state.categories[index] = updatedCategory;
                }
            }
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.DELETE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET:
            state = {
                ...state,
                categoryObj: null,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                categoryObj: action.payload.object,
            };
            break;
        case ActionConstant.GET_ALL:
            state = {
                ...state,
                PROCESSING: true,
                categories: [],
            };
            break;
        case ActionConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                categories: action.payload.objects,
            };
            break;

        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default CategoryReducer;
