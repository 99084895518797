import { takeLatest, put, take, call } from "redux-saga/effects";
import BaseSaga from "../Base";
import * as REST from "../../../Services/rest.service";

import * as ExamActions from "../../Actions/Admin/Exams";
import { ExamTypes as ActionConstant } from "../../Actions/Types/Exams";
import { failed } from "../../Actions/Admin/Exams";

import axiosInstance from "../../../Util/axios";

const message = "Request Failed!";
let err = new Error(message);
err.status = 500;

const endPoint = "/admin/exams";
const studentEndpoint = "/student";

const sagaWatchers = BaseSaga(endPoint, failed);

const getPublishedExams = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/get-published-exams");
};

const getUnPublishedExams = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/get-unpublished-exams");
};

const searchExams = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/search");
};

const getExamsWithQueryParams = function* (action) {
    const queryString = action.payload.queryString;
    yield sagaWatchers.getAll(action, endPoint + "?" + queryString);
};

const createSum = function* (action) {
    yield sagaWatchers.create(
        action,
        endPoint + "/" + action.payload.examId + "/sums"
    );

    yield put(ExamActions.getSums({ examId: action.payload.examId }));
};

const updateSum = function* (action) {
    yield sagaWatchers.update(
        action,
        endPoint + "/" + action.payload.examId + "/sums"
    );
    yield put(ExamActions.getSums({ examId: action.payload.examId }));
};

const getSums = function* (action) {
    yield sagaWatchers.getAll(
        action,
        endPoint + "/" + action.payload.examId + "/sums"
    );
};

const getSum = function* (action) {
    yield sagaWatchers.getOne(
        action,
        endPoint + "/" + action.payload.examId + "/sums"
    );
};

const deleteSum = function* (action) {
    yield sagaWatchers.remove(
        action,
        endPoint + "/" + action.payload.examId + "/sums"
    );
    yield take(ActionConstant.DELETE_SUM_SUCCESS);
    yield put(
        ExamActions.getSums({
            examId: action.payload.examId,
        })
    );
};

const purchaseExam = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/purchase");
};

const getUserExams = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/get-user-exams");
};

const examAttempt = function* (action) {
    yield sagaWatchers.post(action, studentEndpoint + "/attempt-exam");
};

const getExamAttempt = function* (action) {
    yield sagaWatchers.post(action, studentEndpoint + "/get-exam-attempt");
};

const completeExamAttempt = function* (action) {
    yield sagaWatchers.post(action, studentEndpoint + "/complete-exam-attempt");
};

const updateQuestionAnswer = function* (action) {
    yield sagaWatchers.post(action, studentEndpoint + "/update-qtn-answer");
};

const refreshData = function* (action) {
    yield put(ExamActions.getAll());
};

const getAllExamsAttempted = function* (action) {
    yield sagaWatchers.post(action, studentEndpoint + "/all-exam-attempts");
};

const assignExams = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/assign-exams");
};

const getExamsAssigned = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/get-assigned-exams");
};

const getFormData = (data) => {
    let formData = new FormData();
    formData.set("examId", data.examId);
    formData.set("file", data.file);
    return formData;
};

const uploadSums = function* (action) {
    try {
        const formData = getFormData(action.payload);
        let response = yield call(
            REST.postFormdData(endPoint + "/sums/upload", formData)
        );

        console.log(response);

        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
            yield put(ExamActions.getSums({ examId: action.payload.examId }));
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
};

function* downloadCSV(action) {
    try {
        const axios = axiosInstance();
        const response = yield call(
            axios.get,
            endPoint + "/" + action.payload.examId + "/download-sums",
            {
                responseType: "blob",
            }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "sums.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        yield put(ExamActions.downloadCSVSuccess(response.data));
    } catch (error) {
        yield put(failed({ err }));
    }
}

const SagaActions = {
    ...sagaWatchers,
};

export function* watchExamRequests() {
    yield takeLatest(ActionConstant.CREATE, SagaActions.create);
    yield takeLatest(ActionConstant.UPDATE, SagaActions.update);
    yield takeLatest(ActionConstant.DELETE, SagaActions.remove);
    yield takeLatest(ActionConstant.GET_ALL, SagaActions.getAll);

    yield takeLatest(ActionConstant.GET, SagaActions.getOne);
    yield takeLatest(ActionConstant.GET_SUMS, getSums);
    yield takeLatest(ActionConstant.GET_SUM, getSum);
    yield takeLatest(ActionConstant.CREATE_SUM, createSum);
    yield takeLatest(ActionConstant.UPDATE_SUM, updateSum);
    yield takeLatest(ActionConstant.DELETE_SUM, deleteSum);

    yield takeLatest(ActionConstant.GET_PUBLISHED_EXAMS, getPublishedExams);
    yield takeLatest(ActionConstant.GET_UNPUBLISHED_EXAMS, getUnPublishedExams);
    yield takeLatest(ActionConstant.SEARCH_EXAMS, searchExams);
    yield takeLatest(ActionConstant.PURCHASE_EXAMS, purchaseExam);
    yield takeLatest(ActionConstant.GET_USER_EXAMS, getUserExams);

    yield takeLatest(
        ActionConstant.GET_EXAMS_BY_QUERY_STRING,
        getExamsWithQueryParams
    );

    yield takeLatest(
        [ActionConstant.CREATE_SUCCESS, ActionConstant.UPDATE_SUCCESS],
        refreshData
    );

    yield takeLatest(ActionConstant.EXAM_ATTEMPT, examAttempt);
    yield takeLatest(
        ActionConstant.UPDATE_QUESTION_ANSWER,
        updateQuestionAnswer
    );

    yield takeLatest(ActionConstant.COMPLETE_EXAM_ATTEMPT, completeExamAttempt);
    yield takeLatest(ActionConstant.GET_EXAM_ATTEMPT, getExamAttempt);
    yield takeLatest(
        ActionConstant.GET_ALL_EXAMS_ATTEMPTED,
        getAllExamsAttempted
    );
    yield takeLatest(
        ActionConstant.GET_EXAMS_ASSIGNGED_TO_SCHOOL,
        getExamsAssigned
    );
    yield takeLatest(ActionConstant.ASSIGN_EXAMS_TO_SCHOOL, assignExams);
    yield takeLatest(ActionConstant.UPLOAD_SUMS, uploadSums);
    yield takeLatest(ActionConstant.DOWNLOAD_CSV, downloadCSV);
}
