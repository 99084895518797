import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    Col,
    Row,
    Button,
    Breadcrumb,
    ButtonGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Utils from "../../Util/util";
import DataTable from "../../Components/DataTable";
import RequestStatus from "../../Components/RequestStatus";
import { getAll, getOne } from "../../Store/Actions/Admin/Schools";
import NewSchool from "./NewSchool";
import EditSchool from "./EditSchool";

const ListSchools = (props) => {
    const schoolState = useSelector((state) => state.schools);
    const dispatch = useDispatch();

    const getSchool = (id) => {
        dispatch(getOne({ id }));
    };

    const actionButtons = (cell, row) => {
        return (
            <>
                <ButtonGroup aria-label="Actions" vertical>
                    <Button
                        variant="primary"
                        onClick={() => {
                            getSchool(row.id);
                            props.setEditDialog(true);
                        }}
                    >
                        <i className="fa fa-edit fa-lg" aria-hidden="true" />{" "}
                        Edit
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    const columns = [
        {
            dataField: "name",
            text: "Name",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "address",
            text: "Address",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },

        {
            dataField: "state",
            text: "State",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "city",
            text: "City",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "pinCode",
            text: "Pin Code",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "contactPerson",
            text: "Contact Person",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
        {
            dataField: "#",
            text: "...",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    return (
        <div>
            <DataTable
                keyField="id"
                columns={columns}
                data={schoolState.schools ?? []}
                search={true}
                pagination={true}
                button={false}
                dialogButton={true}
                dialogAction={props.setDialog}
                buttonLabel="Add New"
                className="transaction-table"
            />
        </div>
    );
};

const Schools = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [showDialog, setDialog] = useState(false);
    const [showEditDialog, setEditDialog] = useState(false);
    const [state, setState] = useState({});

    const stateObject = useSelector((state) => state.schools);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    return (
        <>
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <Breadcrumb.Item active>Schools</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <NewSchool
                                setAlertStatus={setAlertStatus}
                                showDialog={showDialog}
                                setDialog={setDialog}
                            />

                            <EditSchool
                                setAlertStatus={setAlertStatus}
                                showDialog={showEditDialog}
                                setDialog={setEditDialog}
                            />
                            <ListSchools
                                setDialog={setDialog}
                                setEditDialog={setEditDialog}
                            />
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default Schools;
