import { takeLatest, put } from "redux-saga/effects";
import BaseSaga from "../Base";
import { CategoryTypes } from "../../Actions/Types/Category";
import { failed, getAll as getAllCategories } from "../../Actions/Admin/Category";

const endPoint = "/admin/category";

const sagaWatchers = BaseSaga(endPoint, failed);
const SagaActions = {
    ...sagaWatchers,
};

const refreshData = function* (action) {
    yield put(getAllCategories());
};

export function* watchCategoryRequests() {
    yield takeLatest(CategoryTypes.CREATE, SagaActions.create);
    yield takeLatest(CategoryTypes.UPDATE, SagaActions.update);
    yield takeLatest(CategoryTypes.DELETE, SagaActions.remove);
    yield takeLatest(CategoryTypes.GET_ALL, SagaActions.getAll);
    yield takeLatest(CategoryTypes.GET, SagaActions.getOne);

    yield takeLatest([CategoryTypes.CREATE_SUCCESS, CategoryTypes.UPDATE_SUCCESS], refreshData);
}
