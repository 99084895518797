import { useDispatch } from "react-redux";

const { Row, Card, Col, Button } = require("react-bootstrap");
const { purchasePackage } = require("../Store/Actions/Admin/Packages");

const PackageCard = (props) => {
    const dispatch = useDispatch();
    const buyPackage = (selectedPackage) => {
        const data = {
            packageId: selectedPackage.id,
            name: selectedPackage.name,
            amount: selectedPackage.subscriptionCharges,
            createdDate: new Date(),
            ...props.search_state,
        };
        dispatch(purchasePackage(data));
    };
    return (
        <Row className="listing">
            {props.packages.map((item, index) => {
                return (
                    <Col md={3} key={index}>
                        <Card border="light" bg="light">
                            <Card.Header
                                bg="primary"
                                className="bg-secondary text-white"
                            >
                                {item.name}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>{item.description}</Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col xs={4} md={6}>
                                        Category :
                                    </Col>
                                    <Col xs={8} md={6}>
                                        {item.category.name}
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col xs={6} md={6}>
                                        Total Exams :
                                    </Col>
                                    <Col xs={8} md={6}>
                                        {item.exams
                                            ? item.exams.length
                                            : item.packageExams?.length}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={4} md={6}>
                                        Price :
                                    </Col>
                                    <Col xs={8} md={4}>
                                        Rs. {item.subscriptionCharges}/-
                                    </Col>
                                </Row>
                                {/* <Row className="mt-2">
                                    <Col xs={4} md={4}>
                                        Duration :
                                    </Col>
                                    <Col xs={6} md={4}>
                                        {item.totalTime} min
                                    </Col>
                                </Row>  */}
                            </Card.Body>
                            <div className="d-grid mt-2 ">
                                {props.purchased ? (
                                    ""
                                ) : (
                                    <Button
                                        variant="success"
                                        size="xs"
                                        type="button"
                                        onClick={() => {
                                            buyPackage(item);
                                        }}
                                    >
                                        Buy
                                    </Button>
                                )}
                            </div>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

export default PackageCard;
