import React, { useState, useEffect } from "react";

export default function ExamTimer({ exam, examAttempt, duration, onTimeUp }) {
    const [secondsLeft, setSecondsLeft] = useState(duration);
    useEffect(() => {
        if (exam && examAttempt) {
            const currentTime = new Date();
            const startTime = new Date(examAttempt.startTime);
            const diff_duration = Math.round(
                (currentTime.getTime() - startTime.getTime()) / 1000 / 60
            );

            console.log(currentTime, ":", startTime, ":", diff_duration);
            if (exam) {
                const examDuration = exam.totalTime;
                if (diff_duration > examDuration) {
                    onTimeUp();
                } else if (diff_duration > 0 && diff_duration < examDuration) {
                    setSecondsLeft((examDuration - diff_duration) * 60);
                } else {
                    setSecondsLeft(examDuration * 60);
                }
            }
        }
    }, [exam, examAttempt]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (secondsLeft > 0) {
                setSecondsLeft((prevSeconds) => prevSeconds - 1);
            } else {
                clearInterval(timer);
                onTimeUp();
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [secondsLeft, onTimeUp]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return <div className="timer-container">{formatTime(secondsLeft)}</div>;
}
