import { ExamTypes as ActionConstant } from "../../Actions/Types/Exams";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    examId: 0,
    totalPages: 0,
    exams: [],
    sums: [],
    sum: null,
    exam: null,
    publishedExams: [],
    unPublishedExams: [],
    userExams: [],
    assignedExams: [],
    examAttempt: null,
    examAttempts: [],
    examResult: null,
    downloading: false,
};

const ExamReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.CREATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.UPDATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.UPDATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.DELETE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET:
            state = {
                ...state,
                exam: null,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                exam: action.payload.object,
            };
            break;
        case ActionConstant.GET_ALL:
        case ActionConstant.GET_EXAMS_BY_QUERY_STRING:
        case ActionConstant.SEARCH_EXAMS:
            state = {
                ...state,
                PROCESSING: true,
                exams: [],
            };
            break;
        case ActionConstant.GET_ALL_SUCCESS:
        case ActionConstant.GET_EXAMS_BY_QUERY_STRING_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                exams: action.payload.objects,
            };
            break;

        case ActionConstant.SEARCH_EXAMS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                exams: action.payload.objects,
                totalPages: action.payload.totalPages,
            };
            break;

        case ActionConstant.GET_USER_EXAMS:
            state = {
                ...state,
                PROCESSING: true,
                userExams: [],
            };
            break;

        case ActionConstant.GET_USER_EXAMS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                userExams: action.payload.objects,
                userTotalPages: action.payload.totalPages,
            };
            break;

        case ActionConstant.GET_SUMS:
            state = {
                ...state,
                PROCESSING: true,
                sums: [],
            };
            break;

        case ActionConstant.GET_SUMS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                sums: action.payload.objects,
            };
            break;

        case ActionConstant.GET_SUM:
            state = {
                ...state,
                PROCESSING: true,
                sum: null,
            };
            break;
        case ActionConstant.GET_SUM_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                sum: action.payload.object,
            };
            break;

        case ActionConstant.UPLOAD_SUMS:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;

        case ActionConstant.UPLOAD_SUMS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
            };
            break;

        case ActionConstant.CREATE_SUM:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_SUM_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                sum: action.payload.object,
            };
            break;

        case ActionConstant.UPDATE_SUM:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.UPDATE_SUM_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                sum: action.payload.object,
            };
            break;

        case ActionConstant.GET_PUBLISHED_EXAMS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                publishedExams: action.payload.objects,
            };
            break;

        case ActionConstant.GET_UNPUBLISHED_EXAMS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                unPublishedExams: action.payload.objects,
            };
            break;
        case ActionConstant.PURCHASE_EXAMS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                exams: action.payload.objects,
                totalPages: action.payload.totalPages,
            };
            break;

        case ActionConstant.EXAM_ATTEMPT:
        case ActionConstant.GET_EXAM_ATTEMPT:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.EXAM_ATTEMPT_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                examAttempt: action.payload.object,
            };
            break;
        case ActionConstant.COMPLETE_EXAM_ATTEMPT:
        case ActionConstant.GET_EXAM_ATTEMPT_RESULT:
            state = {
                ...state,
                PROCESSING: true,
                examResult: null,
            };
            break;

        case ActionConstant.COMPLETE_EXAM_ATTEMPT_SUCCESS:
        case ActionConstant.GET_EXAM_ATTEMPT_SUCCESS:
        case ActionConstant.RESET_EXAM_ATTEMPT_STATE:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                examAttempt: action.payload.object,
                examResult: action.payload.examResult,
            };
            break;

        case ActionConstant.GET_EXAM_ATTEMPT_RESULT_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                examResult: action.payload.examResult,
            };
            break;
        case ActionConstant.GET_ALL_EXAMS_ATTEMPTED:
            state = {
                ...state,
                PROCESSING: true,
                examAttempts: [],
            };
            break;

        case ActionConstant.GET_ALL_EXAMS_ATTEMPTED_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                examAttempts: action.payload.objects,
            };
            break;

        case ActionConstant.ASSIGN_EXAMS_TO_SCHOOL:
        case ActionConstant.GET_EXAMS_ASSIGNGED_TO_SCHOOL:
            state = {
                ...state,
                PROCESSING: true,
                assignedExams: [],
            };
            break;
        case ActionConstant.ASSIGN_EXAMS_TO_SCHOOL_SUCCESS:
        case ActionConstant.GET_EXAMS_ASSIGNGED_TO_SCHOOL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                assignedExams: action.payload.objects,
                message: action.payload.message,
            };
            break;

        case ActionConstant.DOWNLOAD_CSV:
            state = {
                ...state,
                REQUEST_SUCCESS: false,
                PROCESSING: true,
                downloading: true,
            };
            break;

        case ActionConstant.DOWNLOAD_CSV_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                PROCESSING: false,
                downloading: false,
            };
            break;

        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
                downloading: false,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default ExamReducer;
