import { takeLatest, put, call } from "redux-saga/effects";
import BaseSaga from "./Base";
import { DashboardTypes } from "../Actions/Types/Dashboard";
import { failed } from "../Actions/Dashboard";

import * as REST from "../../Services/rest.service";
import { logout } from "../Actions/Auth";
import { TOKEN_EXPIRED } from "../Actions/Types/Auth";

const endPoint = "/school/dashboard";
const studentEndPoint = "/student/dashboard";

const message = "Request Failed!";
let err = new Error(message);
err.status = 500;

const getSchoolDashboard = function* (action, url = null) {
    try {
        if (!url) {
            url = endPoint;
        }
        let response = yield call(REST.post(url, action.payload));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
};

const getStudentDashboard = function* (action, url = null) {
    try {
        if (!url) {
            url = studentEndPoint;
        }
        let response = yield call(REST.post(url, action.payload));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
};

export function* watchDashboadRequests() {
    yield takeLatest(DashboardTypes.GET_SCHOOL_DASHBOARD, getSchoolDashboard);
    yield takeLatest(DashboardTypes.GET_STUDENT_DASHBOARD, getStudentDashboard);
}
