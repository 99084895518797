import { PackageTypes as ActionConstant } from "../../Actions/Types/Packages";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    packageId: 0,
    packages: [],
    publishedPackages: [],
    unPublishedPackages: [],
    package: null,
    totalPages: 0,
    userTotalPages: 0,
    userPackages: [],
    assingedPackages: [],
};

const PackageReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.CREATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.UPDATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.UPDATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.DELETE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET:
            state = {
                ...state,
                package: null,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                package: action.payload.object,
            };
            break;
        case ActionConstant.GET_ALL:
        case ActionConstant.SEARCH_PACKAGES:
            state = {
                ...state,
                PROCESSING: true,
                packages: [],
            };
            break;
        case ActionConstant.GET_USER_PACKAGES:
            state = {
                ...state,
                PROCESSING: true,
                userPackages: [],
            };
            break;
        case ActionConstant.PURCHASE_PACKAGES:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                packages: action.payload.objects,
            };
            break;
        case ActionConstant.SEARCH_PACKAGES_SUCCESS:
        case ActionConstant.PURCHASE_PACKAGES_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                packages: action.payload.objects,
                totalPages: action.payload.totalPages,
            };
            break;
        case ActionConstant.GET_USER_PACKAGES_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                userPackages: action.payload.objects,
                userTotalPages: action.payload.totalPages,
            };
            break;

        case ActionConstant.GET_PUBLISHED_PACKAGES_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                publishedPackages: action.payload.objects,
            };
            break;

        case ActionConstant.GET_UNPUBLISHED_PACKAGES_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                unPublishedPackages: action.payload.objects,
            };
            break;

        case ActionConstant.ASSIGN_PACKAGES_TO_SCHOOL:
        case ActionConstant.GET_PACKAGES_ASSIGNED_TO_SCHOOL:
            state = {
                ...state,
                PROCESSING: true,
                assingedPackages: [],
            };
            break;
        case ActionConstant.ASSIGN_PACKAGES_TO_SCHOOL_SUCCESS:
        case ActionConstant.GET_PACKAGES_ASSIGNED_TO_SCHOOL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                assingedPackages: action.payload.objects,
                message: action.payload.message,
            };
            break;

        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default PackageReducer;
